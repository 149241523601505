import { Redirect, RouteComponentProps } from "@reach/router"
import React, { FunctionComponent } from "react"

type Props = RouteComponentProps & { as: FunctionComponent; isLoggedIn: boolean };

const ProtectedRoute: FunctionComponent<Props> = ({ as: Component, ...props }) => {
  const { ...rest } = props
  return props.isLoggedIn ? <Component {...rest} /> : <Redirect from="" to="/login" noThrow/>
}

export { ProtectedRoute }