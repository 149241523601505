import React from "react"
import Grid from "@material-ui/core/Grid"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import { FilterMenu, FilterTypes } from "../filter-menu"
import { ButtonData, CardTypes, FeedbackCard } from "../feedback-card"
import { Feedback, FeedbackStatus, SkillMapping, User } from "../../../utils/models"
import Paper from "@material-ui/core/Paper"
import { Button, TextField } from "@material-ui/core"
import { selfEmail, testEmail, trimString } from "../../../services/auth"
import { Alert } from "@material-ui/lab"
import Snackbar from "@material-ui/core/Snackbar"
import { FeedbackData, GET_FEEDBACK, GET_USER, REQUEST_FEEDBACK, UserData } from "../../../services/queries"
import Loading from "../../loading"
import ErrorView from "../../error-view"
import { navigate } from "gatsby"
import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client"
import { DEFAULT_MESSAGE } from "../../../constants"
import DetailFeeback from "../../detail-feedback/detail-feedback"
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import FavoriteIcon from '@material-ui/icons/Favorite'
import VisibilityIcon from '@material-ui/icons/Visibility'
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend'
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary
    },
    graph: {
      minHeight: 400
    },
    card: {
      marginTop: theme.spacing(2)
    },
    email: {
      // marginTop: 20,
      // marginBottom: 10,
      width: "60%",
      textAlign: "center"
    },
    singUp: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      borderRadius: 10,
      backgroundColor: "rgba(129,199,132,0.47)"
    },
    text: {
      // backgroundColor: "rgba(129,199,132,0.53)",
      color: "rgb(255,255,255)",
      fontWeight: 600
      // "&:hover": {
      //   color: "rgba(255,255,255,0.53)",
      //   fontWeight: 600,
      //   backgroundColor:"rgba(129,199,132,0.53)"
      // }
    },
    send: {
      justifyItems: "center",
      textTransform: "initial",
      backgroundColor: "rgb(0,76,64)",
      fontWeight: 600,
      color: "#fff",
      "&:disable": {
        backgroundColor: "rgb(72,169,153)",
        color: "#fff"
      },
      "&:hover": {
        fontWeight: 600,
        backgroundColor: "rgb(0,121,107)",
        color: "rgb(255,255,255)"
      }
    }
  })
)

const CANCEL_REQUEST = gql`
mutation cancelRequest($id: ID!) {
  cancelRequest(id: $id) 
}`

const FOLLOW_UP = gql`
mutation followUp($id: ID!) {
  followUp(id: $id) 
}`

const SEND_THANKS = gql`
mutation sendThanks($uid: ID!) {
  sendThanks(uid: $uid)
}`

interface RequestedProps {
  user: User
}

export default function Requested(props: RequestedProps) {
  const classes = useStyles()
  const [errorValue, setErrorValue] = React.useState(false)
  const [newEmailValue, setNewEmailValue] = React.useState("")
  const [errorOpen, setErrorOpen] = React.useState(false)
  const [openRequestFeedback, setOpenRequestFeedback] = React.useState(false)
  const [feedback, setFeedback]= React.useState<Feedback | undefined>()
  const [openFollowup, setOpenFollowup] = React.useState(false)
  const [openThanks, setOpenThanks] = React.useState(false)
  const viewFeedback = (f: Feedback) => {
    setFeedback(f)
    setOpenRequestFeedback(!openRequestFeedback)
  }

  const handleCloseDialog = () => {
    setOpenRequestFeedback(!openRequestFeedback)
  }

  const handleNewEmail = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setErrorValue(false)
    setNewEmailValue(event.target.value)
  }

  const handleClose = (_?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return
    }
    setErrorOpen(false)
    setOpenFollowup(false)
    setOpenThanks(false)
  }

  const [filter, setFilter] = React.useState<CardTypes | undefined>()

  const client = useApolloClient()
  const [cancelRequest] = useMutation<{ cancelRequest: Boolean },
    { id: string }>(CANCEL_REQUEST)

  const [followUp] = useMutation<{ followUp: Boolean },
    { id: string }>(FOLLOW_UP)

  const [sendThanks] = useMutation<{ sendThanks: Boolean },
    { uid: string }>(SEND_THANKS)

  const [requestFeedback, { error: MError }] = useMutation<{ requestFeedback: Feedback[] },
    { emails: string[]; msg: string; skills: SkillMapping[] }>(REQUEST_FEEDBACK)

  const { error: error2, data: data2 } = useQuery<UserData>(GET_USER)
  const { data, loading, error } = useQuery<FeedbackData>(GET_FEEDBACK)

  if (loading) {
    return <Loading/>
  }

  if (error) {
    return <ErrorView msg={error.message}/>
  }
  if (error2) {
    return <ErrorView msg={error2.message}/>
  }

  if (data2?.user.name == "") {
    typeof window !== `undefined` && navigate("/user/signup")
  }

  const request = (email: string) => {
    console.log("this is the input data for request", [email], DEFAULT_MESSAGE, data2?.user.skillTemplate)
    requestFeedback({
      variables: {
        emails: [email],
        msg: DEFAULT_MESSAGE,
        skills: data2?.user.skillTemplate.map(x => ({
          sid: x.sid,
          subSkills: x.subSkills
        }))!
      }
    })
      .then(result => {
        // update local cache
        const cacheData = client.readQuery<FeedbackData>({
          query: GET_FEEDBACK
        })

        client.writeQuery<FeedbackData>({
          query: GET_FEEDBACK,

          data: {
            feedbacks: cacheData?.feedbacks!.concat(
              result.data?.requestFeedback!
            )!
          }
        })
      })
      .catch(reason => {
        console.log("Error executing promise", MError, reason)
      })
  }

  const requestedData = data!.feedbacks.filter(f => f.from.id == props.user.id).map(f => {
    let type: CardTypes
    let buttonData: ButtonData[] = []
    switch (f.status) {
      case FeedbackStatus.REQUESTED: {
        type = CardTypes.FeedbackRequested
        buttonData = [
          // "View Request",
          // "View Profile",
          {
            name: "Follow Up",
            icon:  <NotificationsActiveIcon style={{ color: "#1976d2"}}/>,
            fn: () => {
              followUp({ variables: { id: f.id } })
              setOpenFollowup(true)
            }
          },
          {
            name: "Cancel",
            icon:  <CancelScheduleSendIcon style={{ color: "#d32f2f"}}/>,
            fn: () => {
              cancelRequest({ variables: { id: f.id } })
              // update local cache
              const cacheData = client.readQuery<FeedbackData>({
                query: GET_FEEDBACK

              })

              client.writeQuery<FeedbackData>({
                query: GET_FEEDBACK,

                data: {
                  feedbacks: cacheData?.feedbacks!.filter(fdbk => fdbk.id != f.id)!
                }
              })
            }
          }
        ]
        break
      }
      case FeedbackStatus.GIVEN: {
        type = CardTypes.FeedbackReceived
        buttonData = [
          {
            name: "Send Thanks",
            icon:  <FavoriteIcon style={{ color: "#dc004e"}}/>,
            fn: () => {
              sendThanks({ variables: { uid: f.to.id } })
              setOpenThanks(true)
            }
          },
          {
            name: "View Feedback",
            icon:  <VisibilityIcon style={{ color: "#388e3c"}}/>,
            fn: () => {
                  viewFeedback(f)
            }
          }
        ]
        break
      }
      case FeedbackStatus.REJECTED: {
        type = CardTypes.FeedbackRejected
        break
      }
    }

    return {
      id: f.id,
      uid: f.to.id,
      name: f.to.name,
      img: f.to.image,
      email: f.to.email,
      type: type,
      buttons: buttonData
    }
  })


  const filteredCards = () => {
    if (filter) {
      return requestedData.filter(x => x.type == filter)
    }
    return requestedData
  }

  const updateFilter = (filter: CardTypes) => {
    if (filter) {
      setFilter(filter)
      return
    }
    setFilter(undefined)
  }

  return (
    <Grid container direction="row" justify={"flex-start"} alignItems={"center"}>
      <Snackbar open={errorOpen} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Please enter business(official) email only. This is to ensure quality feedback and reduce spams.
        </Alert>
      </Snackbar>
      <Snackbar open={openFollowup} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success"> Follow up request sent.. </Alert>
      </Snackbar>
      <Snackbar open={openThanks} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success"> Greeting, Thanks! email sent.. </Alert>
      </Snackbar>
      <Grid item xs={12}>
        <Paper className={classes.singUp}>
          <Grid container alignItems={"center"} direction={"row"}>
            <Grid item xs={10} container direction={"row"} alignItems={"center"}>
              <Grid item classes={{ root: classes.email }}>
                <TextField
                  fullWidth
                  error={errorValue}
                  value={newEmailValue}
                  onChange={handleNewEmail}
                  id="outlined-email-add-text"
                  label="Email of a collegue..."
                  inputMode={"email"}
                  InputLabelProps={{
                    style: { color: "rgba(255,255,255,0.53)", fontWeight: 600 }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.text
                    }
                  }}
                />
              </Grid>
              <Grid item style={{ marginLeft: 10 }}>
                <Button
                  className={classes.send}
                  // disabled={newEmailValue.length < 1}
                  onClick={() => {
                    setNewEmailValue(trimString(newEmailValue))
                    console.log("this is new email value", newEmailValue, data2?.user!)
                    if (!testEmail(newEmailValue) || selfEmail(newEmailValue, data2?.user.emails!)) {
                      setErrorValue(true)
                      return
                    }
                    // if (generalEmail(newEmailValue)) {
                    //   setErrorOpen(true)
                    //   setErrorValue(true)
                    //   return
                    // }
                    request(newEmailValue)
                    trackCustomEvent({
                      // string - required - The object that was interacted with (e.g.video)
                      category: "Asked Feedback",
                      // string - required - Type of interaction (e.g. 'play')
                      action: "Click",
                      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                      // number - optional - Numeric value associated with the event. (e.g. A product ID)
                    })
                    setNewEmailValue("")
                    setErrorOpen(false)
                    setErrorValue(false)
                  }}
                  variant="contained"
                  // style={{ height: "7ch", width: `10ch`}}
                > Send Request </Button>
              </Grid>
            </Grid>
            <Grid item xs={2} container justify="flex-end" alignItems={"center"}>
              <FilterMenu
                type={FilterTypes.Requested}
                updateFilter={updateFilter}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item>
        <Grid container spacing={1}>
          {filteredCards()
            .filter(card => card.type != CardTypes.FeedbackRejected)
            .map(card => (
              <Grid item className={classes.card} key={card.id}>
                <FeedbackCard
                  id={card.id}
                  uid={card.uid}
                  type={card.type}
                  name={card.name}
                  email={card.email}
                  img={card.img}
                  buttons={card.buttons}
                />
              </Grid>
            ))}
        </Grid>

        <Grid container spacing={1}>
          {filteredCards()
            .filter(card => card.type == CardTypes.FeedbackRejected)
            .map(card => (
              <Grid item className={classes.card}>
                <FeedbackCard
                  id={card.id}
                  uid={card.uid}
                  type={card.type}
                  name={card.name}
                  email={card.email}
                  img={card.img}
                  buttons={card.buttons}
                />
              </Grid>
            ))}
        </Grid>
      </Grid>
      {
        // important condition to properly unmount the Component
        openRequestFeedback && (
          <DetailFeeback
            open={openRequestFeedback}
            handleclose={handleCloseDialog}
            f={feedback}
          />
        )}
    </Grid>
  )
}
