import React, { ReactNode } from "react"
import Grid from "@material-ui/core/Grid"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import { useQuery } from "@apollo/client"
import { FeedbackData, GET_FEEDBACK, GET_USER, UserData } from "../../services/queries"
import Loading from "../loading"
import ErrorView from "../error-view"
import { Colors, FeedbackStatus } from "../../utils/models"
import SkillsTab from "./skills-tab"
import Paper from "@material-ui/core/Paper"
import Rating from "@material-ui/lab/Rating"
import { Divider, SvgIcon, Typography } from "@material-ui/core"
import { ReactComponent as RatingsIcon } from "../../../static/ratings.svg"
import { graphql, useStaticQuery } from "gatsby"
import ToggleButton from "@material-ui/lab/ToggleButton"
import { FiberManualRecordRounded } from "@material-ui/icons"
import { getSkillsMap } from "../../services/skills"
import Graph from "./graph"
import { getdataMap } from "../../services/data"
import SkillImage from "../../../static/welcome-skills-overview.png"

export interface SkillsProps {
  children?: ReactNode
}

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      height: "100%",
      marginTop: theme.spacing(1)
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      borderRadius: 10
    },
    iconHover1: { color: Colors.hover1 },
    iconHover2: { color: Colors.hover2 },
    iconHover3: { color: Colors.hover3 },
    iconHover4: { color: Colors.hover4 },
    iconHover5: { color: Colors.hover5 },
    score1: { backgroundColor: Colors.hover1 },
    score2: { backgroundColor: Colors.hover2 },
    score3: { backgroundColor: Colors.hover3 },
    score4: { backgroundColor: Colors.hover4 },
    score5: { backgroundColor: Colors.hover5 },
    subskillText: { color: "#000000", fontSize: 12},
    skillText: {
      color: "#404040",
      lineHeight: "1.2rem",
      fontSize: 17,
      fontWeight: 600
    },
    heading:{
      fontSize: 13,
      fontWeight: 600,
    },
    optionButton: {
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    skillFeedback:{
      '&:hover':{
        backgroundColor: "rgba(190,255,204,0.15)",
      },
    },
    selected: {
      // height: 30,
      fontSize: 13,
      textTransform: "initial",
      lineHeight: 1,
      fontWeight: 600,
      borderRadius: 10,
      border: "0",
    },
    skillButton: {
      // height: 25,
      fontSize: 13,
      padding: 7,
      textTransform: "initial",
      // lineHeight: 1.5,
      fontWeight: 500,
      borderRadius: 10,
      border: "0",
      color: "#000000"
    },
    iconEmpty1: { color: "rgba(255,171,194,0.3)" },
    iconEmpty2: { color: "rgb(19,204,164,0.3)" },
    iconEmpty3: { color: "rgb(173,175,222,0.3)" },
    iconEmpty4: { color: "rgb(255,67,104,0.3)" },
    iconEmpty5: { color: "rgb(88,155,255,0.3)" },
    iconEmpty6: { color: "rgb(245,133,100,0.3)" },
    iconEmpty7: { color: "rgb(255,207,125,0.3)" },
    toggle1: { backgroundColor: `${Colors.color1} !important`, color: "#000000", },
    toggle2: { backgroundColor: `${Colors.color2} !important`, color: "#000000",},
    toggle3: { backgroundColor: `${Colors.color3} !important`, color: "#000000",},
    toggle4: { backgroundColor: `${Colors.color4} !important`, color: "#ffffff",},
    toggle5: { backgroundColor: `${Colors.color5} !important`, color: "#ffffff",},
    toggle6: { backgroundColor: `${Colors.color6} !important` , color: "#ffffff",},
    toggle7: { backgroundColor: `${Colors.color7} !important`, color: "#000000",},
    backgroundEmpty1: { backgroundColor: "rgba(255,171,194,0.3) !important" },
    backgroundEmpty2: { backgroundColor: "rgb(19,204,164,0.3) !important" },
    backgroundEmpty3: { backgroundColor: "rgb(173,175,222,0.3) !important" },
    backgroundEmpty4: { backgroundColor: "rgb(255,67,104,0.3) !important" },
    backgroundEmpty5: { backgroundColor: "rgb(88,155,255,0.3) !important" },
    backgroundEmpty6: { backgroundColor: "rgb(245,133,100,0.3) !important" },
    backgroundEmpty7: { backgroundColor: "rgb(255,207,125,0.3) !important" },
    rating: {
      height: 20,
      width: 20,
      marginRight: 1,
      borderRadius: 3
    },
    ssText:{
      fontSize: 13,
      marginBottom: 8,
      fontWeight: 500,
      textAlign: "left",
    },
    date:{
      fontSize: 10,
      marginBottom: 8,
      fontWeight: 500,
      textAlign: "left",
      color: "#cbcbcb"
    },
    skillRating: {
      height: 40,
      width: 40,
      marginRight: 4,
      borderRadius: 5
    },
    feedbackRate:{
      height: 10,
      width: 10,
      marginRight: 1,
    },
    exclusive:{
      textAlign: "left",
      fontSize: 13,
      color: "#dddddd"
    },
    msg:{
      textAlign: "left",
      color: "#929292",
      fontSize: 13
    },
    ssRate:{
      height: 18,
      width: 18,
      marginBottom: 5,
      marginRight: 1,
    },
    from: {
      textAlign: "center",
      fontSize: 17,
      fontWeight: 600,
      color: "#585858"
    },
    improve:{
      fontSize: 13,
    }
  })
)

export default function Skills(props: SkillsProps) {
  const classes = useStyles()
  const [sectionID, setSectionID] = React.useState(0)

  const { allFile } = useStaticQuery(graphql`
  query {
    allFile(
      filter: {
        extension: { eq: "svg" }
        sourceInstanceName: { eq: "skill-icons" }
      }
    ) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
  }
`)
  const iconsMap: Record<string, string> = allFile.edges.reduce(
    (map: any, obj: any) => {
      map[obj.node.name] = obj.node.publicURL
      return map
    },
    {}
  )

  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    // console.log(newValue)
    setSectionID(newValue)
  }

  const { loading: loading2, error: error2, data: data2 } = useQuery<UserData>(GET_USER)
  const { loading: loading1, data: data1, error: error1 } = useQuery<FeedbackData>(GET_FEEDBACK)

  if (loading1 || loading2) return <Loading/>
  if (error1) return <ErrorView msg={error1.message}/>
  if (error2) return <ErrorView msg={error2.message}/>

  const requestedFeedbacks = data1?.feedbacks.filter(f => f.from.id == data2?.user.id)
  const receivedFeedbacks = requestedFeedbacks?.filter(f => f.status == FeedbackStatus.GIVEN)
  let skillsMap = getSkillsMap(receivedFeedbacks!)

  var index = 0
  console.log("Master data ", skillsMap)

  const getIconFilledVar = (value: number, type: string) => {
    switch (true) {
      case value <= 1: {
        if (type == "fillBgColor") {
          return (classes.score1)
        }
        return (classes.iconHover1)
      }
      case value <= 2 && value > 1: {
        if (type == "fillBgColor") {
          return (classes.score2)
        }
        return (classes.iconHover2)
      }
      case value <= 3 && value > 2: {
        if (type == "fillBgColor") {
          return (classes.score3)
        }
        return (classes.iconHover3)
      }
      case value <= 4 && value > 3: {
        if (type == "fillBgColor") {
          return (classes.score4)
        }
        return (classes.iconHover4)
      }
      case value > 4: {
        if (type == "fillBgColor") {
          return (classes.score5)
        }
        return (classes.iconHover5)
      }
    }
  }

  const getEmptyIconColor = (color: Colors) => {
    switch (color) {
      case Colors.color1: {
        return (classes.iconEmpty1)
      }
      case Colors.color2: {
        return (classes.iconEmpty2)
      }
      case Colors.color3: {
        return (classes.iconEmpty3)
      }
      case Colors.color4: {
        return (classes.iconEmpty4)
      }
      case Colors.color5: {
        return (classes.iconEmpty5)
      }
      case Colors.color6: {
        return (classes.iconEmpty6)
      }
      case Colors.color7: {
        return (classes.iconEmpty7)
      }
    }
  }

  const getToggleButtonStyle = (color: Colors, type: string) => {
    switch (color) {
      case Colors.color1: {
        if (type == "selected") {
          return (`${classes.toggle1} ${classes.selected}`)
        }
        return (`${classes.backgroundEmpty1} ${classes.skillButton}`)
      }
      case Colors.color2: {
        if (type == "selected") {
          return (`${classes.toggle2} ${classes.selected}`)
        }
        return (`${classes.backgroundEmpty2} ${classes.skillButton}`)
      }
      case Colors.color3: {
        if (type == "selected") {
          return (`${classes.toggle3} ${classes.selected}`)
        }
        return (`${classes.backgroundEmpty3} ${classes.skillButton}`)
      }
      case Colors.color4: {
        if (type == "selected") {
          return (`${classes.toggle4} ${classes.selected}`)
        }
        return (`${classes.backgroundEmpty4} ${classes.skillButton}`)
      }
      case Colors.color5: {
        if (type == "selected") {
          return (`${classes.toggle5} ${classes.selected}`)
        }
        return (`${classes.backgroundEmpty5} ${classes.skillButton}`)
      }
      case Colors.color6: {
        if (type == "selected") {
          return (`${classes.toggle6} ${classes.selected}`)
        }
        return (`${classes.backgroundEmpty6} ${classes.skillButton}`)
      }
      case Colors.color7: {
        if (type == "selected") {
          return (`${classes.toggle7} ${classes.selected}`)
        }
        return (`${classes.backgroundEmpty7} ${classes.skillButton}`)
      }
    }
  }

  return (
    <div className={classes.root}>
      { receivedFeedbacks?.length! < 1 && (
        <Grid item container alignItems={"flex-start"} style={{marginBottom: 40, minHeight: '100ch'}}>
          <img style={{backgroundSize: 'contain', width: '100%', backgroundRepeat: 'no-repeat', borderRadius: 10}}
               alt={"skills page overview"} src={SkillImage} />
        </Grid>
      )}

      { receivedFeedbacks?.length! > 0 && (
      <Grid container>
        <Grid item xs={3}>
          <SkillsTab dataMap={getdataMap(iconsMap)} skillsMap={skillsMap} onChange={handleChange} sectionID={sectionID}/>
        </Grid>
        <Grid item xs={9}>
          {[...skillsMap.entries()].map((x) => {
            index = index + 1
            let skillID = [...skillsMap!.entries()][sectionID]
            return (
              <TabPanel key={x[0]} value={sectionID} index={index - 1}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                      <Paper className={classes.paper}>
                       <Grid container >
                        <Grid item xs={6} >
                          <Graph skill={x[1]}/>
                        </Grid>
                         <Grid item xs={6} container key={index - 1} justify={"flex-start"}
                               style={{ paddingLeft: 20, minHeight: 300 }} >
                           <Grid item container direction={"row"} alignItems={"center"}>
                             <Grid item xs={5} >
                               <Typography classes={{
                                 root: classes.skillText
                               }}> {getdataMap(iconsMap)[skillID[0]].name} </Typography>
                             </Grid>
                             <Grid item xs={6} container justify={"flex-start"} style={{paddingLeft: 20}}>
                               <Rating
                                 readOnly
                                 name="read-only"
                                 value={skillID[1].skillAvgRating}
                                 precision={0.5}
                                 icon={<SvgIcon {...props} className={classes.skillRating}>
                                   <RatingsIcon/>
                                 </SvgIcon>}
                                 classes={{
                                   iconFilled: getIconFilledVar(skillID[1].skillAvgRating, "fillColor"),
                                   iconEmpty: getEmptyIconColor(getdataMap(iconsMap)[skillID[0]].color),
                                 }}
                               />
                             </Grid>
                           </Grid>
                           <Grid container style={{marginTop: 30, alignSelf: "flex-start"}} direction={"row-reverse"}>
                            {[...skillID[1].subskillsAvgRating.entries()].map((x) => {
                              return (
                                <Grid item xs={4} key={x[0]} style={{ paddingLeft: 20, textAlign: "left" }}>
                                  <Rating
                                    readOnly
                                    name="read-only"
                                    value={x[1]}
                                    precision={0.5}
                                    icon={<SvgIcon {...props} className={classes.rating}>
                                      <RatingsIcon/>
                                    </SvgIcon>}
                                    classes={{
                                      iconFilled: getIconFilledVar(x[1], "fillColor"),
                                      iconEmpty: getEmptyIconColor(getdataMap(iconsMap)[skillID[0]].color),
                                    }}
                                  />
                                  <Typography classes={{
                                    root: classes.subskillText
                                  }}> {getdataMap(iconsMap)[skillID[0]].subskills[x[0]].name} </Typography>
                                </Grid>
                              )
                            })}
                            </Grid>
                           <Grid item container style={{marginTop: 30, alignSelf: "flex-end"}} alignItems={"flex-end"} direction={"column"} >
                           <Grid item container alignItems={"flex-end"} justify={"flex-start"} >
                                 <Typography classes={{root: classes.improve}}>
                                   Most people think you can improve by :
                                 </Typography>
                              </Grid>
                           <Grid item  container direction={"row"} alignItems={"flex-end"} justify={"flex-start"} style={{marginTop: 5}}>
                              {[...skillID[1].topImprovementz.entries()].map((x) => {
                               return(
                                 <Grid item className={classes.optionButton} key={x[0]}>
                                  <ToggleButton key={getdataMap(iconsMap)[skillID[0]].improvements[x[0]]}
                                   value={false} className={getToggleButtonStyle(getdataMap(iconsMap)[skillID[0]].color, "selected")}
                                  >
                                    { x[1] != 1 && (x[1])} {getdataMap(iconsMap)[skillID[0]].improvements[x[0]]}
                                  </ToggleButton>
                                </Grid>
                               ) })}
                              </Grid>
                         </Grid>
                          </Grid>
                       </Grid>
                      </Paper>
                  </Grid>
                  <Grid item>
                    <Paper className={classes.paper}>
                      <Grid container direction={"column"}>
                        <Grid item container alignItems={"center"}>
                          <Grid item xs={3}></Grid>
                          <Grid item xs={4} style={{textAlign: "left"}}>
                            <Typography classes={{root: classes.heading}}>360° Feedback</Typography></Grid>
                          <Grid item xs={5}  style={{textAlign: "center"}}>
                            <Typography classes={{root: classes.heading}}>
                              Things I recommend you to improve</Typography></Grid>
                        </Grid>
                          {skillID[1].feedbacks.map((x) => {
                            // In case number of days logic for date is needed
                            // var t = Math.ceil((Math.abs(new Date().getTime() - new Date(x.updated).getTime())) / (1000 * 3600 * 24))
                        return(
                          <Grid container key={x.id} className={classes.skillFeedback}  >
                          <Grid item  container alignItems={"center"} justify={"flex-start"} style={{marginTop: 30,}}>
                          <Grid item xs={3} container alignItems={"center"} direction={"column"} style={{alignContent: "flex-start"}}>
                            <Grid item >
                              <Typography classes={{root: classes.from}}>
                                {x.to.name}
                               </Typography>
                               </Grid>
                             <Grid item style={{paddingLeft: 20}}>
                               <Rating
                                 readOnly
                                 name="read-only"
                                 value={(x.skillFeedbacks[0].subSkillRatings.reduce((sum , current) => sum + current.rating, 0)) / (x.skillFeedbacks[0].subSkillRatings.length) }
                                 precision={0.5}
                                 classes={{
                                   iconFilled: getIconFilledVar((x.skillFeedbacks[0].subSkillRatings.reduce((sum , current) => sum + current.rating, 0)) / (x.skillFeedbacks[0].subSkillRatings.length), "fillColor"),
                                   iconEmpty: getEmptyIconColor(getdataMap(iconsMap)[skillID[0]].color),
                                 }}
                               />
                             </Grid>
                            <Grid item>
                              <Typography classes={{root: classes.date}}> {new Date(x.updated).toDateString()} </Typography>
                            </Grid>
                          </Grid>
                          <Grid item xs={4} container  direction={"column"}>
                              {x.skillFeedbacks[0].subSkillRatings.map((s)=>{
                              return(
                                <Grid container key={s.sid}>
                                  <Grid item xs={6}>
                                      <Typography classes={{root: classes.ssText}}> {getdataMap(iconsMap)[x.skillFeedbacks[0].sid].subskills[s.sid].name} </Typography>
                                 </Grid>
                                  <Grid item xs={1}></Grid>
                                  <Grid item xs={4} style={{alignSelf: "center"}}>
                                    <Rating
                                      readOnly
                                      name="read-only"
                                      value={s.rating}
                                      precision={0.5}
                                      icon={<SvgIcon {...props} className={classes.ssRate}>
                                        <FiberManualRecordRounded/>
                                      </SvgIcon>}
                                      classes={{
                                        iconFilled: getIconFilledVar(s.rating, "fillColor"),
                                        iconEmpty: getEmptyIconColor(getdataMap(iconsMap)[skillID[0]].color),
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              )})}
                          </Grid>
                          <Grid item xs={5} container alignItems={"center"} justify={"center"}>
                            {x.skillFeedbacks[0].improvements.map((s)=>{
                              return(
                                <Grid item key={`${s}-grid`} >
                                  <Grid item className={classes.optionButton} key={s}>
                                    <ToggleButton key={s} value={false}
                                                  className={getToggleButtonStyle(getdataMap(iconsMap)[skillID[0]].color, "random")}>
                                      {getdataMap(iconsMap)[skillID[0]].improvements[s]}
                                    </ToggleButton>
                                  </Grid>
                                </Grid>
                              )})}
                          </Grid>
                            { x.skillFeedbacks[0].msg && (
                              <Grid item container style={{marginTop: 10}}>
                                <Grid item xs={3}></Grid>
                                <Grid item xs={2} style={{alignSelf: "flex-start"}}>
                                  <Typography classes={{root: classes.exclusive}}>Exclusive Feedback</Typography>
                                </Grid>
                                <Grid item xs={7} style={{alignSelf: "flex-start", paddingLeft: 30}}>
                                  <Typography classes={{root: classes.msg}}>{x.skillFeedbacks[0].msg}</Typography>
                                </Grid>
                              </Grid>
                            )}
                            {skillID[1].feedbacks.length > 1 && (
                              <Grid item xs={12} style={{marginTop: 10}}>
                                <Divider variant="middle" style={{backgroundColor: "rgba(72,169,153,0.42)"}}/>
                              </Grid>
                            )}
                        </Grid>
                          </Grid>
                        ) })}
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </TabPanel>
            )
          })}

        </Grid>
      </Grid>
      )}
    </div>
  )
}