import React from "react"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles"
import { Typography } from "@material-ui/core"
import Switch from "@material-ui/core/Switch"
import { GET_USER, UserData } from "../../services/queries"
import { useMutation, useQuery, gql } from "@apollo/client"
import { NOTICATIONS } from "../../constants"
import { EmailSubscriptions, NewProfileInput, User } from "../../utils/models"


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      minHeight: `80vh`,
      padding: theme.spacing(2),
      textAlign: "left",
      color: theme.palette.text.secondary,
      borderRadius: 10
    }
  })
)

const PurpleSwitch = withStyles({
  switchBase: {
    color: "#004c40",
    "&$checked": {
      color: "#004c40"
    },
    "&$checked + $track": {
      backgroundColor: "#48a999"
    }
  },
  checked: {},
  track: {}
})(Switch)

// const tempData = new Map()
// tempData.set("You’ve received new feedback", true)
// tempData.set("You’ve received a feedback request", true)
// tempData.set("You’ve received thanks from a peer", true)
// tempData.set("Johari app and feature updates", false)
// tempData.set("Quarterly Strengths and weakeness report", true)
// tempData.set("Feedback Reminders", false)

export const UPDATE_SUBSCRIPTION = gql`
mutation updateName($input: ProfileInput!) {
  updateProfile(input: $input) {
    id
    emailSubscriptions
  }
}
`

export default function Notification() {
  const classes = useStyles()
  const { data } = useQuery<UserData>(GET_USER)
  const state = new Set(data?.user.emailSubscriptions)
  const [updateEmail] = useMutation<{ updateTemplates: User },
  { input: NewProfileInput }>(UPDATE_SUBSCRIPTION)

  const handleChange = (key: EmailSubscriptions) => {
    let temp = new Set(state)
    temp.has(key)? temp.delete(key): temp.add(key)
    updateEmail({variables: {
      input: {
        emailSubscriptions: [...temp.values()]
      }
    }})
  }

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Paper className={classes.paper}>
          {[...NOTICATIONS.entries()].map((v) => {
            return (
              <Grid key={v[0]} container alignItems={"center"}>
                <Grid item xs={9} style={{ paddingLeft: 20 }}>
                  <Typography style={{ lineHeight: 3, color: "#312f2f", fontFamily: "Roboto Mono, monospace" }}>
                    {v[1]}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <PurpleSwitch
                    checked={state.has(v[0])}
                    onChange={() => {
                      handleChange(v[0])
                    }
                    }/>
                </Grid>
              </Grid>
            )
          })}
        </Paper>
      </Grid>
    </Grid>
  )
}