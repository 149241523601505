import React from "react"
import ToggleButton from "@material-ui/lab/ToggleButton"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import { Skill } from "../../../utils/models"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(),
      overflow: "auto",
      minHeight: "60vh"
      // maxHeight: "60vh"
    },
    skillButton: {
      width: theme.spacing(20),
      height: theme.spacing(20),
      fontSize: 18,
      textTransform: "initial",
      color: "#000000",
      lineHeight: 1,
      fontWeight: 500,
      borderRadius: 20,
      marginBottom: theme.spacing(3),
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      border: "0",
      "&:hover": {
        fontWeight: 600,
        backgroundColor: "rgba(72,166,151,0.47)",
        color: "rgb(0,119,105)"
      }
    },
    skillIcon: {
      height: "90px"
    },
    selected: {
      fontWeight: 600,
      backgroundColor: "rgb(72,166,151, 0.3) !important",
      color: "rgb(0,74,63) !important"
    }
  })
)

interface SkillCardsProps {
  skills: Skill[]
  // use to speedup lookup queries compared to array above
  skillsMap: Record<number, Skill>
  selected: Map<number, Set<number>>
  skillIcons: Record<string, string>
  setSelected: React.Dispatch<React.SetStateAction<Map<number, Set<number>>>>
}

export default function SkillCards(props: SkillCardsProps) {
  const classes = useStyles()

  return (
    <Paper elevation={0} className={classes.root}>
      <Grid container justify="center">
        {props.skills.map(x => (
          <Grid item key={x.id}>
            <ToggleButton
              classes={{ selected: classes.selected }}
              selected={props.selected?.has(x.id)}
              disabled={
                props.selected?.size > 7 && !props.selected.has(x.id)
              }
              onChange={() => {
                let tmp = new Map(props.selected)
                if (tmp?.delete(x.id)) {
                  props.setSelected(tmp)
                  return
                }
                if (tmp.size < 8) {
                  props.setSelected(
                    tmp?.set(
                      x.id,
                      new Set(props.skillsMap[x.id].subskills.map(s => s.id))
                    )
                  )
                }
              }}
              className={classes.skillButton}
            >
              <Grid container direction="column">
                <Grid item>
                  <img
                    src={props.skillIcons[x.logo]}
                    alt="logo"
                    className={classes.skillIcon}
                  />
                </Grid>
                <Grid item style={{ marginTop: 10 }}>{x.name}</Grid>
              </Grid>
            </ToggleButton>
          </Grid>
        ))}
      </Grid>
    </Paper>
  )
}
