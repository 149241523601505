import { gql } from "@apollo/client"
import { Feedback, User } from "../utils/models"

export const GET_USER = gql`
query getUser {
  user{
    id
    name
    image
    desc
    emailHistory
    emailSubscriptions
    emails {
      value
      type
    }
    skillTemplate {
      sid
      subSkills
    }
  }
}

`

export const GET_FEEDBACK = gql`
query getFeedbacks {
  feedbacks {
    id
    from {
      id
      email
      name
      desc
      image
    }
    to {
       id
      email
      name
      desc
      image
    }
    created
    updated
    status
    updated
    status
    request {
      msg
      skills {
        sid
        subSkills
      }
    }
    qualities
    msg
    skillFeedbacks {
      sid
      skipped
      msg
      improvements
      subSkillRatings {
        sid
        rating
      }
    }
  }
}
`

export const UPDATE_NAME = gql`
  mutation updateName($input: ProfileInput!) {
    updateProfile(input: $input) {
      id
      name
    }
  }
`

export const UPDATE_DESC = gql`
  mutation updateDesc($input: ProfileInput!) {
    updateProfile(input: $input) {
      id
      desc
    }
  }
`

export const UPDATE_TEMPLATE = gql`
  mutation updateTemplates($input: ProfileInput!) {
    updateProfile(input: $input) {
      id
      name
      skillTemplate {
        sid
        subSkills
      }
    }
  }
`

export const UPDATE_HISTORY = gql`
mutation updateHistory($input: ProfileInput!) {
  updateProfile(input: $input) {
    id
    emailHistory
  }
}`

export const REQUEST_FEEDBACK = gql`
  mutation requestFeedback(
    $emails: [String!]!
    $msg: String!
    $skills: [SkillMappingInput!]!
  ) {
    requestFeedback(emails: $emails, msg: $msg, skills: $skills) {
      id
      status
      from {
        id
        name
        email
        desc
        image
      }
      to {
        id
        name
        email
        desc
        image
      }
      created
      updated
      request {
        msg
        skills {
          sid
          subSkills
        }
      }
    }
  }
`

export interface UserData {
  user: User
}

export interface FeedbackData {
  feedbacks: Feedback[]
}