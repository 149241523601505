import React from "react"
import Grid from "@material-ui/core/Grid"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import { data } from "../../../data"
import EmailInput from "./email-input"
import SkillCards from "./skill-cards"
import { Feedback, NewProfileInput, Skill, SkillMapping, User } from "../../../utils/models"
import Divider from "@material-ui/core/Divider"
import { DialogActions, DialogContent, DialogTitle } from "../dialog"
import { useApolloClient, useMutation, useQuery } from "@apollo/client"
import { selfEmail } from "../../../services/auth"
import { Alert } from "@material-ui/lab"
import Snackbar from "@material-ui/core/Snackbar"
import {
  FeedbackData,
  GET_FEEDBACK,
  GET_USER,
  REQUEST_FEEDBACK,
  UPDATE_HISTORY,
  UPDATE_TEMPLATE,
  UserData
} from "../../../services/queries"
import Loading from "../../loading"
import ErrorView from "../../error-view"
import { DEFAULT_MESSAGE } from "../../../constants"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    choseSkillsContent: {
      margin: theme.spacing(1)
    },
    dialogPaper: {
      borderRadius: 15
    },
    dialogTitle: {
      color: "rgb(0,74,63)",
      fontWeight: 700,
      alignItems: "center",
      fontSize: 18,
      fontFamily: "Merriweather"
    },
    personalize: {
      color: "#117f3d",
      textTransform: "initial",
      fontSize: 18,
      fontWeight: 500,
      marginRight: 20,
      textDecorationLine: "underline"
    },
    email: {
      marginBottom: 20,
      paddingLeft: 20
    },
    sendContainer: {
      paddingLeft: "1vw",
      justifyItems: "center",
      alignItems: "center"
    },
    send: {
      justifyItems: "center",
      backgroundColor: "rgb(0,76,64)",
      fontWeight: 600,
      color: "#fff",
      height: "40px",
      width: "30px",
      "&:disable": {
        backgroundColor: "rgb(72,169,153)",
        color: "#fff"
      },
      "&:hover": {
        fontWeight: 600,
        backgroundColor: "rgb(0,121,107)",
        color: "rgb(255,255,255)"
      }
    },
    skillButton: {
      borderRadius: 20,

      color: "#000000",
      paddingLeft: 10,
      height: 80
    },
    logo: {
      height: 55,
      width: 55,
      margin: 0,
      justifyItems: "center",
      alignItems: "center"
    },
    skillsName: {
      justifyContent: "flex-start",
      alignItems: "center"
    },
    skillCardText: {
      width: 150,
      lineHeight: "1.5rem"
    },
    subSkillCard: {
      backgroundColor: "rgba(0 ,119,105,0.05)",
      borderRadius: 20,
      padding: theme.spacing(2)
    },
    subSkillCardGrid: {
      padding: theme.spacing(2),
      width: 300
    }
  })
)

interface RequestFeedbackProps {
  open: boolean
  handleClose: () => void
  skillIcons: Record<string, string>
  skillsMap: Record<number, Skill>
}

export default function RequestFeedback(props: RequestFeedbackProps) {
  const classes = useStyles()
  const [finalPage, setFinalPage] = React.useState(false)
  const togglePage = () => {
    setFinalPage(!finalPage)
  }

  const handleClose = () => {
    props.handleClose()
    // setFinalPage(false)
  }

  // skills is used to fetch subskills of a skill without iterating through the array
  const skillsMap = data.reduce((map: Record<number, Skill>, obj: Skill) => {
    map[obj.id] = obj
    return map
  }, {})

  const [updateHistory] = useMutation<{ updateHistory: User },
    { input: NewProfileInput }>(UPDATE_HISTORY)

  const { loading, error, data: data2 } = useQuery<UserData>(GET_USER)

  const [customize, setCustomize] = React.useState(
    data2!.user.skillTemplate.length < 1
  )
  const mappedData = data2!.user.skillTemplate.map(
    x => [x.sid, new Set(x.subSkills)] as [number, Set<number>]
  )

  const [selected, setSelected] = React.useState(new Map(mappedData))

  const [selectedCopy, setSelectedCopy] = React.useState(selected)
  const [text, setText] = React.useState(DEFAULT_MESSAGE)
  const [emails, setEmails] = React.useState<string[]>([])
  const [emailInput, setEmailInput] = React.useState("")
  const [emailError, setEmailError] = React.useState("")
  const [bEError, setBEError] = React.useState(false)

  const handleClosebEError = (_?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return
    }
    setBEError(false)
  }

  const handleChangeMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value)
  }

  const [requestFeedback, { error: MError }] = useMutation<{ requestFeedback: Feedback[] },
    { emails: string[]; msg: string; skills: SkillMapping[] }>(REQUEST_FEEDBACK)

  const request = () => {
    const tmp = customize ? selected : selectedCopy
    requestFeedback({
      variables: {
        emails: emails,
        msg: text,
        skills: [...tmp.entries()].map(v => ({
          sid: v[0],
          subSkills: [...v[1].values()]
        }))
      }
    })
      .then(result => {
        // update local cache
        const cacheData = client.readQuery<FeedbackData>({
          query: GET_FEEDBACK
        })

        client.writeQuery<FeedbackData>({
          query: GET_FEEDBACK,

          data: {
            feedbacks: cacheData?.feedbacks!.concat(
              result.data?.requestFeedback!
            )!
          }
        })
      })
      .catch(reason => {
        console.log("Error executing promise", MError, reason)
      })

    // update the email history for auto complete
    const emailSet = new Set(data2?.user.emailHistory)
    let sync = false
    emails.forEach(e => {
      if (!sync) {
        sync = !emailSet.has(e)
      }
      emailSet.add(e)
    })

    if (sync) {
      updateHistory({
        variables: {
          input: {
            emailHistory: [...emailSet.values()]
          }
        }
      })
    }
  }
  const client = useApolloClient()

  const [updateTemplates] = useMutation<{ updateTemplates: User },
    { input: NewProfileInput }>(UPDATE_TEMPLATE, {
    variables: {
      input: {
        skillTemplate: [...selected.entries()].map(v => ({
          sid: v[0],
          subSkills: [...v[1].values()]
        }))
      }
    }
  })

  if (loading) return <Loading/>
  if (error) return <ErrorView msg={error.message}/>

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      fullWidth={true}
      maxWidth={"lg"}
      scroll={"paper"}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
        back={togglePage}
        enableBack={finalPage}
      >
        <span className={classes.dialogTitle}>
          {!customize && "Request Feedback"}
          {
            customize && (
              <span className={classes.dialogTitle}> Choose your Skills</span>
            )
            // <span style={{color: "rgba(86,88,86,0.59)", fontSize: 15,}}>(Upto 8)</span></span>
          }
        </span>
        <Divider variant="middle" style={{ marginTop: 10 }}/>
      </DialogTitle>
      {!customize && (
        <DialogContent>
          <Grid
            container
            direction="column"
            justify="center"
            className={classes.choseSkillsContent}
            aria-describedby="scroll-dialog-description"
          >
            <Grid item container justify="flex-end" alignItems="flex-start">
              <Button
                className={classes.personalize}
                onClick={() => {
                  setCustomize(true)
                }}
              >
                Personalize
              </Button>
            </Grid>
            <Grid item>
              <SkillCards
                skillIcons={props.skillIcons}
                skills={[...selected.keys()].map(x => skillsMap[x])}
                skillsMap={skillsMap}
                selected={selectedCopy}
                setSelected={setSelectedCopy}
              />
            </Grid>
          </Grid>
        </DialogContent>
      )}
      {customize && !finalPage && (
        <DialogContent>
          <Grid
            container
            direction="column"
            justify="center"
            className={classes.choseSkillsContent}
          >
            <Grid item>
              <SkillCards
                skillIcons={props.skillIcons}
                skills={data}
                selected={selected}
                setSelected={setSelected}
                skillsMap={skillsMap}
              />
            </Grid>
          </Grid>
        </DialogContent>
      )}

      {customize && finalPage && (
        // code for the final page (step 2)
        <DialogContent>
          <Grid container direction="column" justify="center">
            <Grid item container direction="row">
              {[...selected.keys()].map(skill => (
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="flex-start"
                  justify="center"
                  className={classes.subSkillCardGrid}
                >
                  <Grid item container className={classes.subSkillCard}>
                    <Grid
                      item
                      container
                      alignItems="center"
                      spacing={1}
                      className={classes.skillButton}
                    >
                      <Grid item>
                        <img
                          className={classes.logo}
                          src={props.skillIcons[skillsMap[skill].logo]}
                          alt={skillsMap[skill].name}
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="h6"
                          className={classes.skillCardText}
                        >
                          {skillsMap[skill].name}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item container style={{ paddingLeft: "4vw" }}>
                      <FormGroup>
                        {skillsMap[skill].subskills.map(s => (
                          <FormControlLabel
                            key={s.name}
                            control={
                              <Checkbox
                                disabled={
                                  selected.get(skill)?.size == 1 &&
                                  selected.get(skill)?.has(s.id)
                                }
                                style={{
                                  color: "#007769"
                                }}
                                checked={selected.get(skill)?.has(s.id)}
                                onChange={() => {
                                  let tmp = new Set(selected.get(skill))
                                  if (!tmp.delete(s.id)) {
                                    tmp.add(s.id)
                                  }
                                  setSelected(new Map(selected).set(skill, tmp))
                                }}
                                name={s.name}
                              />
                            }
                            label={s.name}
                          />
                        ))}
                      </FormGroup>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </DialogContent>
      )}

      <DialogActions>
        {!customize && (
          <Grid container direction="row">
            <Grid item xs={11} className={classes.email}>
              <EmailInput
                emailInput={emailInput}
                emails={emails}
                emailError={emailError}
                emailOptions={data2?.user.emailHistory}
                setEmails={setEmails}
                setEmailInput={setEmailInput}
                setEmailError={setEmailError}
                label={"Email of colleagues (Press enter)"}
              />
            </Grid>
            <Grid item container xs={1} className={classes.sendContainer}>
              <Button
                variant="contained"
                className={classes.send}
                autoFocus
                onClick={() => {
                  for (let email of emails) {
                    if (selfEmail(email, data2?.user.emails!)) {
                      return
                    }
                    // if (generalEmail(email)) {
                    //   setBEError(true)
                    //   return
                    // }
                  }
                  if (emails.length < 1) {
                    setEmailError("Please enter atleast 1 email")
                    return
                  }
                  console.log(emails, selectedCopy)
                  request()
                  trackCustomEvent({
                    // string - required - The object that was interacted with (e.g.video)
                    category: "Asked Feedback",
                    // string - required - Type of interaction (e.g. 'play')
                    action: "Click",
                    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                    // number - optional - Numeric value associated with the event. (e.g. A product ID)
                  })
                  props.handleClose()
                }}
                disabled={selectedCopy?.size < 3}
              >
                Send
              </Button>
            </Grid>
          </Grid>
        )}
        {!finalPage && customize && (
          <Grid container direction="row">
            <Grid item xs={11} className={classes.email}>
              <EmailInput
                emailInput={emailInput}
                emails={emails}
                emailError={emailError}
                emailOptions={data2?.user.emailHistory}
                setEmails={setEmails}
                setEmailInput={setEmailInput}
                setEmailError={setEmailError}
                label={"Email of colleagues (Press enter)"}
              />
            </Grid>
            <Grid item container xs={1} className={classes.sendContainer}>
              <Button
                variant="contained"
                className={classes.send}
                autoFocus
                onClick={() => {
                  for (let email of emails) {
                    if (selfEmail(email, data2?.user.emails!)) {
                      return
                    }
                    // if (generalEmail(email)) {
                    //   setBEError(true)
                    //   return
                    // }
                  }
                  if (emails.length < 1) {
                    setEmailError("Please enter atleast 1 email")
                    return
                  }
                  togglePage()
                }}
                disabled={selected?.size < 3}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        )}

        {finalPage && (
          <Grid container direction="row">
            <Grid item xs={11} className={classes.email}>
              <TextField
                value={text}
                id="standard-basic"
                label="Edit Request Message"
                multiline={true}
                fullWidth={true}
                onChange={handleChangeMessage}
              />
            </Grid>
            <Grid item container xs={1} className={classes.sendContainer}>
              <Button
                variant="contained"
                className={classes.send}
                autoFocus
                onClick={() => {
                  setSelectedCopy(selected)
                  console.log("Printing both selected and selectedcopy", selected, selectedCopy)
                  request()
                  trackCustomEvent({
                    // string - required - The object that was interacted with (e.g.video)
                    category: "Asked Feedback",
                    // string - required - Type of interaction (e.g. 'play')
                    action: "Click",
                    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                    // number - optional - Numeric value associated with the event. (e.g. A product ID)
                  })
                  updateTemplates().catch(reason => {
                    console.log("failed to send update template ", reason)
                  })
                  props.handleClose()
                }}
              >
                Send
              </Button>
            </Grid>
          </Grid>
        )}
      </DialogActions>
      <Snackbar
        open={bEError}
        autoHideDuration={5000}
        onClose={handleClosebEError}
      >
        <Alert onClose={handleClosebEError} severity="error">
          Please enter business(official) emails of peers. This is to ensure quality
          feedback and reduce spams.
        </Alert>
      </Snackbar>
    </Dialog>
  )
}
