import React, { ReactNode, useEffect } from "react"
import Grid from "@material-ui/core/Grid"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import Summary from "./summary"
import Received from "./received/recieved"
import Requested from "./requested/requested"
import Shared from "./shared/shared"
import { useQuery } from "@apollo/client"
import { navigate } from "gatsby"
import { FeedbackData, GET_FEEDBACK, GET_USER, UserData } from "../../services/queries"
import Loading from "../loading"
import ErrorView from "../error-view"
import { FeedbackStatus } from "../../utils/models"

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      //aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  )
}

export interface FeedbackProps {
  children: ReactNode
}

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      height: "100%"
      // margin: theme.spacing(0),
    }
  })
)

export default function Feedback() {
  const classes = useStyles()
  // sectionID is the id of the section show on the left side (feedback received, requested or shared)
  const { loading: loading1, error, data } = useQuery<UserData>(GET_USER)
  const { loading: loading2, error: error2, data: data2 } = useQuery<FeedbackData>(GET_FEEDBACK)

  const received = data2?.feedbacks.filter(f => f.from.id == data?.user.id).filter(f => f.status == FeedbackStatus.GIVEN).length
  const reqeusts = data2?.feedbacks.filter(f => f.to.id == data?.user.id).length!
  console.log("request and requests", received, reqeusts, received == 0 && reqeusts > 0)
  const [sectionID, setSectionID] = React.useState(0)
  console.log("section id", sectionID)

  useEffect(() => {
    if (received == 0 && reqeusts > 0) {
      console.log("setting section id")
      setSectionID(2)
    }
  }, [data2])

  if (loading1 || loading2) return <Loading/>

  if (error) return <ErrorView msg={error.message}/>
  if (error2) return <ErrorView msg={error2.message}/>

  if (data?.user.name == "") {
    typeof window !== `undefined` && navigate("/user/signup")
  }

  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setSectionID(newValue)
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={3}>
          <Summary onChange={handleChange} sectionID={sectionID} user={data?.user!}/>
        </Grid>
        <Grid item xs={9}>
          <TabPanel value={sectionID} index={0}>
            <Received user={data?.user!}/>
          </TabPanel>
          <TabPanel value={sectionID} index={1}>
            <Requested user={data?.user!}/>
          </TabPanel>
          <TabPanel value={sectionID} index={2}>
            <Shared user={data?.user!}/>
          </TabPanel>
        </Grid>
      </Grid>
    </div>
  )
}
