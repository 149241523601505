import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles"
import Tab from "@material-ui/core/Tab"
import Grid from "@material-ui/core/Grid"
import Tabs from "@material-ui/core/Tabs"
import React from "react"
import Paper from "@material-ui/core/Paper"
import { Typography } from "@material-ui/core"
import { SkillData, SkillsPageObject } from "../../utils/models"

interface OptionProps {
  onChange: (event: React.ChangeEvent<{}>, value: any) => void
  sectionID: number
  skillsMap: Map<number, SkillsPageObject>
  dataMap: Record<number, SkillData>
}

const CustomTab = withStyles((_: Theme) => ({
  selected: {
    backgroundColor: "rgba(72,169,153,0.22)",
    fontWeight: 600,
    color: "#004a3f",
    borderRadius: 3
  },
  root: {
    // height: 5,
    marginTop: 5,
    opacity: 0.97
  },
  wrapper: {
    flexDirection: "row",
    textAlign: "left",
    justifyContent: "flex-start",
    alignItems: "center",
    color: "#007769",
    textTransform: "initial",
    marginTop: 8,
    marginBottom: 8,
    fontSize: 15,
    fullWidth: "true"
  }
}))(Tab)

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      borderRadius: 20,
      textTransform: "initial",
      fontWeight: 600,
      fontSize: 16
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(2),
      borderRadius: 10,
      minHeight: 220,
      overflow: "auto",
      maxHeight: "100vh",
      marginBottom: 30
    },
    tabGrid: {
      alignContent: "center",
      marginTop: 5,
      display: "flex",
      width: "100%",
      flexContainer: {
        display: "flex"
      }
    },
    logout: {
      justifyItems: "center",
      // backgroundColor: "rgba(32,153,23,0.29)",
      fontWeight: 600,
      color: "#fff",
      "&:hover": {
        fontWeight: 600,
        backgroundColor: "rgba(184,82,114,0.55)",
        color: "rgb(255,255,255)"
      }
    },
    logo: {
      marginRight: 10
    }
  })
)

export default function SkillsTab(props: OptionProps) {
  const classes = useStyles()
  return (
    <Grid>
      <Paper className={classes.paper}>
        <Grid item container direction="row" className={classes.tabGrid}>
          <Tabs
            orientation="vertical"
            value={props.sectionID}
            onChange={props.onChange}
            aria-label="Vertical tabs"
            TabIndicatorProps={{
              style: {
                height: 45,
                width: 1,
                alignItems: "center"
              }
            }}
            className={classes.tabGrid}
          >
            {[...props.skillsMap.entries()].map((x) => (
              <CustomTab
                key={props.dataMap[x[0]].name} disableRipple={true}
                label={<>
                  <Grid item>
                    <img
                      style={{ height: 50, width: 50 }}
                      className={classes.logo}
                      src={props.dataMap[x[0]].iconURL}
                      alt="logo"
                    />
                  </Grid>
                  {props.dataMap[x[0]].name}
                  <Grid container justify={"flex-end"}>
                    <Typography> {Math.ceil(x[1].skillAvgRating * 2)} </Typography>
                    {/*<Badge anchorOrigin={{*/}
                    {/*  vertical: 'top',*/}
                    {/*  horizontal: 'right',*/}
                    {/*}} badgeContent={Math.ceil(x[1].skillAvgRating * 2)} >*/}
                    {/*  <Rating  max={1} precision={0.1} name="read-only" value={x[1].skillAvgRating / 5} readOnly />*/}
                    {/*</Badge>*/}
                    {/*<Rating  max={1} precision={0.1} name="read-only" value={x[1].skillAvgRating / 5} readOnly />*/}
                  </Grid>
                </>} {...a11yProps(props.sectionID)} />
            ))}

          </Tabs>
        </Grid>
      </Paper>
    </Grid>
  )
}