import React from "react"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"
import { testEmail, trimString } from "../../../services/auth"

interface EmailInputProps {
  emailInput: string
  emails: string[]
  emailError: string
  emailOptions?: string[]
  setEmails: React.Dispatch<React.SetStateAction<string[]>>
  setEmailInput: React.Dispatch<React.SetStateAction<string>>
  setEmailError: React.Dispatch<React.SetStateAction<string>>
  label: string
  type?: string
}

export default function EmailInput(props: EmailInputProps) {

  if (props.type == "settingEmail") {
    return (
      <Autocomplete
        multiple
        freeSolo
        id="tags-standard"
        inputValue={props.emailInput ? props.emailInput : ""}
        value={props.emails}
        options={props.emailOptions ? props.emailOptions : []}
        getOptionLabel={option => option}
        onInputChange={(_: object, value: string, reason: string) => {
          // if the email is not valid -> dont allow to reset input
          if (!(reason == "reset" && !testEmail(trimString(props.emailInput)))) {
            props.setEmailInput(trimString(value))
          }
        }}
        onChange={(
          _1: React.ChangeEvent<{}>,
          value: string[],
          reason: string
        ) => {
          // If the email is not valid -> dont add to email array
          if (
            !(
              reason == "create-option" &&
              !testEmail(value[value.length - 1])
            )
          ) {
            props.setEmails(value)
            if (props.emailError.length > 0) {
              props.setEmailError("")
            }
          }
        }}
        // defaultValue={[top100Films[13]]}
        renderInput={params => (
          <TextField
            {...params}
            error={props.emailError.length > 0}
            helperText={props.emailError}
            variant="outlined"
            label={props.label}
            // placeholder="email address"
          />
        )}
      />
    )
  }

  return (
    <Autocomplete
      multiple
      freeSolo
      id="tags-standard"
      inputValue={props.emailInput ? props.emailInput : ""}
      value={props.emails}
      options={props.emailOptions ? props.emailOptions : []}
      getOptionLabel={option => option}
      onInputChange={(_: object, value: string, reason: string) => {
        // if the email is not valid -> dont allow to reset input
        if (!(reason == "reset" && !testEmail(trimString(props.emailInput)))) {
          props.setEmailInput(value)
        }
      }}
      onChange={(
        _1: React.ChangeEvent<{}>,
        value: string[],
        reason: string
      ) => {
        // If the email is not valid -> dont add to email array
        if (
          !(
            reason == "create-option" &&
            !testEmail(value[value.length - 1])
          )
        ) {
          props.setEmails(value)
          if (props.emailError.length > 0) {
            props.setEmailError("")
          }
        }
      }}
      // defaultValue={[top100Films[13]]}
      renderInput={params => (
        <TextField
          {...params}
          error={props.emailError.length > 0}
          helperText={props.emailError}
          variant="standard"
          label={props.label}
          placeholder="email address"
        />
      )}
    />
  )
}