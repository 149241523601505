import React from "react"
import Grid from "@material-ui/core/Grid"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import { FilterMenu, FilterTypes } from "../filter-menu"
import { ButtonData, CardTypes, FeedbackCard } from "../feedback-card"
import { Feedback, FeedbackStatus, Skill, User } from "../../../utils/models"
import ShareDialog from "../share-dialog/share-dialog"
import { data } from "../../../data"
import { graphql, useStaticQuery } from "gatsby"
import { FeedbackData, GET_FEEDBACK} from "../../../services/queries"
import { gql, useMutation, useQuery } from "@apollo/client"
import Loading from "../../loading"
import ErrorView from "../../error-view"
import DetailFeeback from "../../detail-feedback/detail-feedback"
import Snackbar from "@material-ui/core/Snackbar"
import { Alert } from "@material-ui/lab"
import VisibilityIcon from '@material-ui/icons/Visibility'
import WarningIcon from '@material-ui/icons/Warning'
import ShareIcon from '@material-ui/icons/Share'
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary
      //   Heigh: '100%'
    },
    graph: {
      minHeight: 400
    },
    card: {
      marginTop: theme.spacing(2)
    }
  })
)

const SET_SPAM = gql`
mutation setSpam($id: ID!) {
  setSpam(id: $id) {
  id
  status
}
}`

interface SharedProps {
  user: User
}

export default function Shared(props: SharedProps) {
  const classes = useStyles()
  const [openShareFeedback, setOpenShareFeedback] = React.useState(false)
  const [feedback, setFeedback]= React.useState<Feedback | undefined>()
  const [currentFeedback, setCurrentFeedback] = React.useState("")
  const [openRequestFeedback, setOpenRequestFeedback] = React.useState(false)
  const [openUnspam, setUnspamOpen] = React.useState(false)
  const [openSpam, setSpamOpen] = React.useState(false)
  const viewFeedback = (f: Feedback) => {
    setFeedback(f)
    setOpenRequestFeedback(!openRequestFeedback)
  }

  const handleClose = (_?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return
    }
   setUnspamOpen(false)
    setSpamOpen(false)
  }

  const handleClickToggleShareFeedback = () => {
    setOpenShareFeedback(!openShareFeedback)
  }

  const handleCloseDialog = () => {
    setOpenRequestFeedback(!openRequestFeedback)
  }

  const [filter, setFilter] = React.useState<CardTypes | undefined>()

  const [selected, setSelected] = React.useState(new Map<number, Set<number>>())
  const [setSpam] = useMutation<{ setSpam: Feedback },
  { id: string }>(SET_SPAM)

  const { data: data1, loading, error } = useQuery<FeedbackData>(GET_FEEDBACK)


  // skills is used to fetch subskills of a skill without iterating through the array
  const skillsMap = data.reduce((map: Record<number, Skill>, obj: Skill) => {
    map[obj.id] = obj
    return map
  }, {})

  const { allFile } = useStaticQuery(graphql`
  query {
    allFile(
      filter: {
        extension: { eq: "svg" }
        sourceInstanceName: { eq: "skill-icons" }
      }
    ) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
  }
`)
  const skillIcons: Record<string, string> = allFile.edges.reduce(
    (map: any, obj: any) => {
      map[obj.node.name] = obj.node.publicURL
      return map
    },
    {}
  )


  if (loading) {
    return <Loading/>
  }

  if (error) {
    return <ErrorView msg={error.message}/>
  }



  const sharedData = data1!.feedbacks.filter(f => f.to.id == props.user.id).map(f => {
    let type: CardTypes
    let buttonData: ButtonData[] = []
    switch (f.status) {
      case FeedbackStatus.REQUESTED: {
        type = CardTypes.FeedbackPending
        buttonData = [
          {
            name: "View Request",
            icon: <ShareIcon  style={{ color: "#1976d2"}}/>,
            fn: () => {
              let tmp = new Map<number, Set<number>>()
              f.request.skills.forEach(v => {
                let tmpSet = new Set<number>(v.subSkills)
                tmp.set(v.sid, tmpSet)
              })
              console.log("setting feedback object from button click", tmp)
              setCurrentFeedback(f.id)
              setSelected(tmp)
              setOpenShareFeedback(true)
            }
          },
          {
            name: "Mark Spam",
            icon: <WarningIcon  style={{ color: "#d32f2f"}}/>,
            fn: () => {
              setSpam({ variables: { id: f.id } })
              setSpamOpen(true)
            }
          }
        ]
        break
      }
      case FeedbackStatus.GIVEN: {
        type = CardTypes.FeedbackShared
        buttonData = [
          {
            name: "View Shared Feedback",
            icon: <VisibilityIcon  style={{ color: "#388e3c"}}/>,
            fn: () => {
              viewFeedback(f)
            }
          },
        ]
        break
      }
      case FeedbackStatus.REJECTED: {
        type = CardTypes.FeedbackSpammed
        buttonData = [
          {
            name: "Unspam",
            icon: <SettingsBackupRestoreIcon  style={{ color: "#f57c00"}}/>,
            fn: () => {
              setSpam({ variables: { id: f.id } })
              setUnspamOpen(true)
            }
          },
        ]
        break
      }
    }
    return {
      id: f.id,
      type: type,
      uid: f.from.id,
      name: f.from.name,
      email: f.from.email,
      img: f.from.image,
      buttons: buttonData
    }
  })



  const filteredCards = (filter?: CardTypes) => {
    if (filter) {
      return sharedData.filter(x => x.type == filter)
    }
    return sharedData
  }

  const updateFilter = (filter: CardTypes) => {
    if (filter) {
      setFilter(filter)
      return
    }
    setFilter(undefined)
  }



  const getName = (id: string) =>{
    const requestedFeedbacks = data1?.feedbacks.filter(f => f.id == id)
    console.log("Fee", requestedFeedbacks)
    return requestedFeedbacks![0].from.name
  }

  return (
    <Grid container direction="column">
      <Snackbar open={openUnspam} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success"> Unspamed account, you can share feedback now .. </Alert>
      </Snackbar>
      <Snackbar open={openSpam} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success"> Marked Spam and Request Rejected.. </Alert>
      </Snackbar>
      <Grid item>
        <Grid container justify="flex-end">
          <FilterMenu type={FilterTypes.Shared} updateFilter={updateFilter}/>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container spacing={1}>
          {filteredCards(filter)
            .filter(card => card.type != CardTypes.FeedbackSpammed && card.type != CardTypes.FeedbackShared)
            .map(card => (
              <Grid item className={classes.card} key={card.id}>
                <FeedbackCard
                  uid={card.uid}
                  id={card.id}
                  type={card.type}
                  name={card.name}
                  email={card.email}
                  img={card.img}
                  buttons={card.buttons}
                />
              </Grid>
            ))}
        </Grid>
        <Grid container spacing={1}>
          {filteredCards(filter)
            .filter(card => card.type == CardTypes.FeedbackSpammed || card.type == CardTypes.FeedbackShared)
            .map(card => (
              <Grid item className={classes.card} key={card.id}>
                <FeedbackCard
                  id={card.id}
                  uid={card.uid}
                  type={card.type}
                  name={card.name}
                  email={card.email}
                  img={card.img}
                  buttons={card.buttons}
                />
              </Grid>
            ))}
        </Grid>
        {
          // important condition to properly unmount the Component
          openShareFeedback && (
            <ShareDialog
              fid={currentFeedback}
              open={openShareFeedback}
              handleClose={handleClickToggleShareFeedback}
              selected={selected}
              skillIcons={skillIcons}
              skillsMap={skillsMap}
              name={getName(currentFeedback)}
            />
          )}
        {
          // important condition to properly unmount the Component
          openRequestFeedback && (
            <DetailFeeback
              open={openRequestFeedback}
              handleclose={handleCloseDialog}
              f={feedback}
            />
          )}
      </Grid>
    </Grid>
  )
}
