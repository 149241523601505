import React from "react"
import Grid from "@material-ui/core/Grid"
import Options from "./options"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import Account from "./account"
import Support from "./support"
import Notification from "./notification"

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  )
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      height: "100%",
      marginTop: theme.spacing(4)
    }
  })
)

export default function Settings() {
  const classes = useStyles()
  const [sectionID, setSectionID] = React.useState(0)

  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setSectionID(newValue)
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={3}>
          <Options onChange={handleChange} sectionID={sectionID}/>
        </Grid>
        <Grid item xs={9}>
          <TabPanel value={sectionID} index={0}>
            <Account/>
          </TabPanel>
          <TabPanel value={sectionID} index={1}>
            <Notification/>
          </TabPanel>
          <TabPanel value={sectionID} index={2}>
            <Support/>
          </TabPanel>
        </Grid>

      </Grid>
    </div>
  )
}