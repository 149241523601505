import React from "react"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Edit from "@material-ui/icons/Edit"
import Save from "@material-ui/icons/Save"
import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import Grid from "@material-ui/core/Grid"
import { useMutation } from "@apollo/client"
import { NewProfileInput, User } from "../../utils/models"
import { UPDATE_DESC, UPDATE_NAME } from "../../services/queries"
import { trimString } from "../../services/auth"

export enum EditableTextboxType {
  Description,
  Name,
}

interface EditableTextboxProps {
  text: string
  type: EditableTextboxType
}

const styles = makeStyles((_: Theme) =>
  createStyles({
    container: {
      height: 10,
      display: "flex",
      flexWrap: "wrap"
    },
    textField: {
      margin: 0,
      marginLeft: 6,
      color: "black",
      flexWrap: "wrap",
      fontSize: 22,
      opacity: 1,
      borderBottom: 0,
      "&:before": {
        borderBottom: 0
      }
    },
    disabled: {
      color: "#5f5e5e",
      borderBottom: 0,
      "&:before": {
        borderBottom: 0
      }
    },
    nameDisabled: {
      color: "#000000",
      fontWeight: 600,
      fontSize: 20,
      borderBottom: 0,
      "&:before": {
        borderBottom: 0
      }
    },
    btnIcons: {
      marginLeft: 10
    }
  })
)

// Editable Textbox
export default function EditableTextField(props: EditableTextboxProps) {
  const classes = styles()
  const characterLimit = 80

  const [editMode, setEditMode] = React.useState(false)
  const [text, setText] = React.useState(props.text)
  const [mouseOver, setMouseOver] = React.useState(false)
  const [visibleIcon, setVisibleIcon] = React.useState(<Edit/>)

  const [updateName] = useMutation<{ updateName: User },
    { input: NewProfileInput }>(UPDATE_NAME, {
    variables: { input: { name: text } }
  })

  const [updateDesc] = useMutation<{ updateDesc: User },
    { input: NewProfileInput }>(UPDATE_DESC, {
    variables: { input: { desc: text } }
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (editMode) {
      setText(event.target.value)
    }
  }

  const handleMouseOver = () => {
    if (!mouseOver) {
      setMouseOver(true)
    }
  }

  const handleMouseOut = () => {
    if (mouseOver) {
      setMouseOver(false)
    }
  }

  const handleClick = () => {
    if (!editMode) {
      setEditMode(true)
      setMouseOver(true)
      setVisibleIcon(<Save/>)
    } else {
      if (props.type == EditableTextboxType.Name) {
        setText(trimString(text))
        if (text.length < 1) {
          return
        }
        updateName().catch(reason => {
          console.log("failed to send update name ", reason)
        })
      }

      if (props.type == EditableTextboxType.Description) {
        setText(trimString(text))
        updateDesc().catch(reason => {
          console.log("failed to send update desc ", reason)
        })
      }
      console.log(text)
      setVisibleIcon(<Edit/>)
      // setText(text)
      setEditMode(false)
      setMouseOver(false)
    }
  }

  if (props.type == EditableTextboxType.Description) {
    return (
      <Grid container>
        {/*<div style={{ textAlign: "left", color: "red", fontSize: "16px", width: "100%", backgroundColor: '#fdfdd5', padding: '5px', lineHeight: '25px' }}>*/}
        {/*   <ul>*/}
        {/*     <li>Email: {JSON.stringify(text)}</li>*/}
        {/*     <li>editMode: {JSON.stringify(editMode)}</li>*/}
        {/*     <li>mouseOver: {JSON.stringify(mouseOver)}</li>*/}
        {/*   </ul>*/}
        {/* </div>*/}

        <TextField
          name="text"
          fullWidth
          value={text}
          margin="normal"
          multiline
          error={text.length == 0}
          onChange={handleChange}
          disabled={!editMode}
          className={classes.textField}
          onMouseEnter={handleMouseOver}
          onMouseLeave={handleMouseOut}
          inputProps={{
            maxLength: characterLimit
          }}
          InputProps={{
            classes: {
              disabled: classes.disabled
            },
            endAdornment: mouseOver ? (
              <InputAdornment position="end">
                <IconButton onClick={handleClick}> {visibleIcon} </IconButton>
              </InputAdornment>
            ) : (
              ""
            )
          }}
        />
      </Grid>
    )
  }

  return (
    <Grid container>
      <TextField
        name="text"
        fullWidth
        value={text}
        margin="normal"
        error={text.length == 0}
        onChange={handleChange}
        disabled={!editMode}
        className={classes.textField}
        onMouseEnter={handleMouseOver}
        onMouseLeave={handleMouseOut}
        inputProps={{
          maxLength: 24
        }}
        InputProps={{
          classes: {
            disabled: classes.nameDisabled
          },
          endAdornment: mouseOver ? (
            <InputAdornment position="end">
              <IconButton onClick={handleClick}> {visibleIcon} </IconButton>
            </InputAdornment>
          ) : (
            ""
          )
        }}
      />
    </Grid>
  )
}
