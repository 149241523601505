import { RouteComponentProps } from "@reach/router"
import { Button, Grid, Paper, TextField, Typography } from "@material-ui/core"
import React from "react"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
// @ts-ignore
import BGImage from "../../static/login-bg.png"
import SkillCards from "../components/feedback/request-dialog/skill-cards"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { useMutation, useQuery } from "@apollo/client"
import { Feedback, NewProfileInput, Skill, SkillMapping, User } from "../utils/models"
import { GET_USER, REQUEST_FEEDBACK, UPDATE_TEMPLATE, UserData } from "../services/queries"
import Loading from "./loading"
import ErrorView from "./error-view"
import { data } from "../data"
import { DEFAULT_MESSAGE } from "../constants"
import { generalEmail, selfEmail, testEmail, trimString } from "../services/auth"
import { Alert } from "@material-ui/lab"
import Snackbar from "@material-ui/core/Snackbar"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"


const useStyles = makeStyles((_: Theme) =>
  createStyles({
    paperContainer: {
      minHeight: "100vh",
      background: "linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%)",
      // backgroundImage: `url(` + BGImage + `)`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      backgroundSize: "cover",
      margin: 0,
      padding: 0,
      backgroundAttachment: "fixed"
    },
    step: {
      margin: 20,
      fontSize: 19,
      color: "rgb(0,0,0)",
      fontWeight: 700
    },
    input: {
      fontWeight: 600,
      fontSize: 19
    },
    send: {
      textTransform: "initial",
      justifyItems: "center",
      backgroundColor: "rgb(76,175,80)",
      fontWeight: 600,
      color: "#fff",
      "&:disable": {
        backgroundColor: "rgb(72,169,153)",
        color: "#fff"
      },
      "&:hover": {
        fontWeight: 600,
        backgroundColor: "rgb(0,121,107)",
        color: "rgb(255,255,255)"
      }
    },
    continue: {
      textTransform: "initial",
      justifyItems: "center",
      backgroundColor: "rgb(0,74,63)",
      fontWeight: 600,
      fontSize: 20,
      color: "#fff",
      "&:disable": {
        backgroundColor: "rgb(72,169,153)",
        color: "#fff"
      },
      "&:hover": {
        fontWeight: 600,
        backgroundColor: "rgb(0,121,107)",
        color: "rgb(255,255,255)"
      }
    },
    skip: {
      textTransform: "initial",
      justifyItems: "center",
      fontWeight: 400,
      fontSize: 20,
      color: "#fff",
      "&:hover": {
        fontWeight: 400,
        backgroundColor: "rgb(0,121,107)",
        color: "rgb(255,255,255)"
      }
      // "&:hover": {
      //   fontWeight: 400,
      //   backgroundColor: "rgba(0,121,107,0.38)",
      //   color: "rgb(255,255,255)"
      // }
    },
    selectSkills: {
      justifyItems: "flex-end",
      padding: 20,
      fontSize: 17,
      fontWeight: 500,
      color: "rgb(0,121,107)"
    },
    desc: {
      paddingRight: 20,
      fontSize: 17,
      fontWeight: 300,
      color: "rgb(50,49,49)"
    },
    title: {
      paddingTop: 20,
      fontSize: 20,
      fontWeight: 600,
      color: "rgb(50,49,49)"
    }
  })
)

export default function Signup(_: RouteComponentProps) {
  const classes = useStyles()
  const [pageNumber, setPageNumber] = React.useState(1)
  const [selected, setSelected] = React.useState(new Map<number, Set<number>>())
  const [nameValue, setNameValue] = React.useState("")
  const [errorValue, setErrorValue] = React.useState(false)
  const [emailError, setEmailError] = React.useState(false)
  const [emailArray, setEmailArray] = React.useState(['','',''])

  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { eq: "svg" }
          sourceInstanceName: { eq: "skill-icons" }
        }
      ) {
        edges {
          node {
            name
            publicURL
          }
        }
      }
    }
  `)

  const skillIcons: Record<string, string> = allFile.edges.reduce(
    (map: any, obj: any) => {
      map[obj.node.name] = obj.node.publicURL
      return map
    },
    {}
  )

  // skills is used to fetch subskills of a skill without iterating through the array
  const skillsMap = data.reduce((map: Record<number, Skill>, obj: Skill) => {
    map[obj.id] = obj
    return map
  }, {})

  const handleName = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setErrorValue(false)
    setNameValue(event.target.value)
  }

  const [updateTemplates] = useMutation<{ updateTemplates: User },
    { input: NewProfileInput }>(UPDATE_TEMPLATE, {
    variables: {
      input: {
        skillTemplate: [...selected.entries()].map(v => ({
          sid: v[0],
          subSkills: [...v[1].values()]
        })),
        name: nameValue
      }
    }
  })
  const [requestFeedback, { error: MError }] = useMutation<{ requestFeedback: Feedback[] },
    { emails: string[]; msg: string; skills: SkillMapping[] }>(REQUEST_FEEDBACK)

  const { loading, error, data: resp } = useQuery<UserData>(GET_USER)

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>, i: number) => {
    let temp = [...emailArray]
    // temp = (emailArray)
    temp[i] = event.target.value
    setEmailArray(temp)
  }
  const handleClose = (_?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return
    }
    setEmailError(false)
  }

  const request = (emails: string[]) => {
    requestFeedback({
      variables: {
        emails: emails,
        msg: DEFAULT_MESSAGE,
        skills: resp?.user.skillTemplate.map(x => ({
          sid: x.sid,
          subSkills: x.subSkills
        }))!
      }
    }).catch(reason => {
        console.log("Error executing promise", MError, reason)
      })
  }

  if (loading) return <Loading/>
  if (error) return <ErrorView msg={error.message}/>
  if (pageNumber == 1 && resp?.user.name != "") {
    typeof window !== `undefined` && navigate("/user")
  }

  return (
    <Grid
      container
      direction={"row"}
      classes={{ root: classes.paperContainer }}
    >
      {pageNumber == 1 && (
        <Grid container direction={"row"}>
          <Grid container alignItems={"center"} style={{ marginTop: 10 }}>
            <Grid item xs={6}>
              <Typography classes={{ root: classes.step }}>
                {" "}
                Step {pageNumber} of 2 :{" "}
              </Typography>
            </Grid>
            <Grid item xs={6} container justify={"flex-end"}>
              <Grid
                item
                container
                justify={"flex-end"}
                alignItems={"center"}
                style={{ marginRight: "19%" }}
              >
                {selected?.size >= 3 && (
                  <Button
                    className={classes.send}
                    variant="contained"
                    style={{ height: "7ch" }}
                    onClick={() => {
                      if (nameValue.length <= 0) {
                        setErrorValue(true)
                      } else {
                        // send data to server
                        setPageNumber(2)
                        updateTemplates()
                      }
                    }}
                  >
                    Continue with selected {selected?.size}
                  </Button>
                )}
                {selected?.size < 3 && (
                  <Typography classes={{ root: classes.step }}>
                    Select 3 or more skills to continue
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            justify={"center"}
            style={{ marginBottom: 160, marginTop: 10 }}
          >
            <Grid item>
              <Paper style={{ width: "80vw" }}>
                <Grid container direction={"row"} style={{ height: "10ch" }}>
                  <Grid item xs={4}>
                    <Grid style={{ margin: 10, marginLeft: 20 }}>
                      <TextField
                        style={{
                          width: "100%",
                          height: "30",
                          justifyItems: "flex-start"
                        }}
                        error={errorValue}
                        value={nameValue}
                        onChange={handleName}
                        id="outlined-full-name-add-text"
                        label="Your Full Name"
                        inputMode={"text"}
                        InputProps={{
                          className: classes.input
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    container
                    justify={"flex-end"}
                    alignItems={"flex-end"}
                    style={{ marginBottom: 10 }}
                  >
                    <Typography classes={{ root: classes.selectSkills }}>
                      <b> Select your best soft-skills (upto 8)... </b>
                    </Typography>
                  </Grid>
                </Grid>
                <SkillCards
                  skillIcons={skillIcons}
                  skills={data}
                  selected={selected}
                  skillsMap={skillsMap}
                  setSelected={setSelected}
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      )}
      {pageNumber == 2 && (
        <Grid container direction={"row"}>
          <Grid container alignItems={"center"} style={{ marginTop: 10 }}>
            <Grid item xs={6}>
              <Typography classes={{ root: classes.step }}>
                {" "}
                Step {pageNumber} of 2 :{" "}
              </Typography>
            </Grid>
            <Grid item xs={6} container justify={"flex-end"}>
              <Grid
                item
                container
                justify={"flex-end"}
                alignItems={"center"}
                style={{ marginRight: "19%" }}
              >
                <Button
                  className={classes.skip}
                  variant="outlined"
                  style={{ height: "5ch" }}
                  onClick={() => {
                    if (nameValue.length <= 0) {
                      setErrorValue(true)
                    } else {
                      typeof window !== `undefined` && navigate("/user")
                      setPageNumber(2)
                    }
                  }}
                >
                  Skip{" "}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            justify={"center"}
            style={{ marginBottom: 100, marginTop: 10 }}
          >
            <Grid item>
              <Paper style={{ width: "40vw", minHeight: "60ch" }}>
                <Grid container justify={"center"}>
                  <Typography classes={{ root: classes.title }}>
                    Who do you work with?
                  </Typography>
                </Grid>
                <Grid container justify={"center"}>
                  {emailArray.map((_, i) => {
                    return (
                      <Grid
                        key={`${i}-index`}
                        item
                        container
                        direction={"column"}
                        style={{ width: "80%", marginTop: 20 }}
                      >
                        <TextField
                          label="name@company.com"
                          variant="outlined"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>)=>{
                            handleTextChange(event, i)
                          }}
                          value={emailArray[i]}
                          inputProps={{
                            maxLength: 30
                          }}
                        />
                      </Grid>
                    )})}
                </Grid>
                <Grid container justify={"center"}>
                  <Grid
                    item
                    container
                    direction={"column"}
                    style={{ width: "80%", marginTop: 10 }}
                  >
                    <Typography variant={"caption"}>
                      Be prepared to discover what your collegues think are your
                      strengths and weakness
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justify={"center"}>
                  <Grid
                    item
                    container
                    direction={"column"}
                    style={{ width: "80%", marginTop: 10 }}
                  >
                    <Button
                      className={classes.continue}
                      variant="contained"
                      style={{ height: "5ch" }}
                      onClick={() => {
                        for (let email of emailArray.filter(x => x != '')) {
                          email = trimString(email)
                          if (!testEmail(email)  || selfEmail(email, resp?.user.emails!)) {
                            setEmailError(true)
                            // console.log(!testEmail(email) , generalEmail(email) , selfEmail(email, resp?.user.emails!))
                            return
                          }
                        }
                        typeof window !== `undefined` && navigate("/user")
                        request(emailArray.filter(x => x != ''))
                        trackCustomEvent({
                          // string - required - The object that was interacted with (e.g.video)
                          category: "Asked Feedback",
                          // string - required - Type of interaction (e.g. 'play')
                          action: "Click",
                          // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                          // number - optional - Numeric value associated with the event. (e.g. A product ID)
                        })
                        }}>
                      Ask Feedback{" "}
                    </Button>
                    <Snackbar open={emailError} autoHideDuration={5000} onClose={handleClose}>
                      <Alert onClose={handleClose} severity="error">
                        Please enter a valid business email of your colleague.
                      </Alert>
                    </Snackbar>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
