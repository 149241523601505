import React from "react"
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Menu, { MenuProps } from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import ScheduleIcon from "@material-ui/icons/Schedule"
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline"
import { green, red, yellow } from "@material-ui/core/colors"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"
import { CardTypes } from "./feedback-card"
import AllOutIcon from "@material-ui/icons/AllOut"

const useStyles = makeStyles((_: Theme) =>
  createStyles({
      menu: {
        "&selected": {
          backgroundColor: "#c26262"
        }
      }
    }
  ))
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5"
  }
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center"
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles(theme => ({
  root: {
    "&:focus": {
      backgroundColor: "rgba(129,199,132,0.47)",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.black
      }
    }
  }
}))(MenuItem)

export enum FilterTypes {
  Requested = 1,
  Shared,
}

interface FilterMenuProps {
  type: FilterTypes
  updateFilter: (filter: CardTypes) => void
}

interface Item {
  id: number
  name: string
  icon: React.ReactNode
}

export function FilterMenu(props: FilterMenuProps) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [buttonName, setButtonName] = React.useState("All Requests")
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    const { type, name } = event.currentTarget.dataset
    if (type != undefined) {
      const filterType = Number(type)
      props.updateFilter(filterType)
      if (name != undefined) {
        setButtonName(name)
      }
    }

    setAnchorEl(null)
  }

  let items: Item[] = []

  switch (props.type) {
    case FilterTypes.Shared: {
      items.push(
        {
          id: 0,
          name: "All Requests",
          icon: <AllOutIcon/>
        },
        {
          id: CardTypes.FeedbackPending,
          name: "Pending",
          icon: <ScheduleIcon style={{ color: yellow[700] }}/>
        },
        {
          id: CardTypes.FeedbackShared,
          name: "Shared",
          icon: <CheckCircleOutlineIcon style={{ color: green[500] }}/>
        },
        {
          id: CardTypes.FeedbackSpammed,
          name: "Spammed",
          icon: <ErrorOutlineIcon style={{ color: red[500] }}/>
        }
      )
      break
    }

    case FilterTypes.Requested: {
      items.push(
        {
          id: 0,
          name: "All Requests",
          icon: <AllOutIcon/>
        },
        {
          id: CardTypes.FeedbackRequested,
          name: "Requested",
          icon: <ScheduleIcon style={{ color: yellow[700] }}/>
        },
        {
          id: CardTypes.FeedbackReceived,
          name: "Received",
          icon: <CheckCircleOutlineIcon style={{ color: green[500] }}/>
        },
        {
          id: CardTypes.FeedbackRejected,
          name: "Rejected",
          icon: <HighlightOffIcon style={{ color: red[500] }}/>
        }
      )
      break
    }
  }

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
        style={{ backgroundColor: "rgb(0,76,64)", textTransform: "initial" }}
      >
        {buttonName} ▼
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items.map(item => (
          <StyledMenuItem key={item.id} className={classes.menu} onClick={handleClose} data-type={item.id}
                          data-name={item.name}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.name}/>
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </div>
  )
}
