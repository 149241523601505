import React from "react"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import StepConnector from "@material-ui/core/StepConnector"
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles"
import { StepIconProps } from "@material-ui/core/StepIcon"
import clsx from "clsx"
import Check from "@material-ui/icons/Check"


const QontoConnector = withStyles({
  alternativeLabel: {
    top: 5,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)"
  },
  active: {
    "& $line": {
      borderColor: "#48a999"
    }
  },
  completed: {
    "& $line": {
      borderColor: "#00796b"
    }
  },
  line: {
    borderColor: "rgba(191,219,191,0.6)",
    borderTopWidth: 3,
    borderRadius: 1
  }
})(StepConnector)

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "rgba(191,219,191,0.6)",
    display: "flex",
    height: 10,
    alignItems: "center"
  },
  active: {
    color: "#48a999"
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor"
  },
  completed: {
    color: "#004c40",
    zIndex: 1,
    fontSize: 18
  }
})

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    designStepper: {
      margin: 0,
      padding: 0,
      paddingTop: 25,
      height: 50
    }
  })
)

function QontoStepIcon(props: StepIconProps) {
  const classes = useQontoStepIconStyles()
  const { active, completed } = props

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active
      })}
    >
      {completed ? <Check className={classes.completed}/> : <div className={classes.circle}/>}
    </div>
  )
}

interface FeedbackStepperProps {
  steps: string[]
  activeStep: number
  setActiveStep: React.Dispatch<React.SetStateAction<number>>
}

export default function FeedbackStepper(props: FeedbackStepperProps) {
  const classes = useStyles()

  return (
    <Stepper
      classes={{
        root: classes.designStepper
      }}
      alternativeLabel activeStep={props.activeStep} connector={<QontoConnector/>}>
      {props.steps.map((label) => (
        <Step key={label}>
          <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}