import React from "react"
import { makeStyles, Theme, withStyles } from "@material-ui/core/styles"
import { AppBar, Grid, isWidthUp, Paper, SvgIcon, Tab, Tabs, Toolbar, Typography } from "@material-ui/core"
import Feedback from "./feedback/feedback"
import Skills from "./skills/skills"
import FingerprintIcon from "@material-ui/icons/Fingerprint"
import Container from "@material-ui/core/Container"
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer"
import Logo from "../../static/scatter_plot-24px.svg"
import Poll from "@material-ui/icons/Poll"
import Settings from "./settings/setting"
import withWidth from "@material-ui/core/withWidth"
import { ReactComponent as EmailSent } from "../../static/email-sent.svg"

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
    >
      {value === index && <div>{children}</div>}
    </div>
  )
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    height: "100%"
  },
  customizeToolbar: {
    minHeight: 10
  },
  logo: {
    height: 40,
    width: 40,
    marginRight: 18,
    alignItems: "flex-start"
  },
  brand: {
    margin: 0,
    // fontFamily: "Merriweather",
    fontWeight: 900,
    fontSize: 24,
    color: "#fff"
    // boxShadow: "4px 4px 4px 2px rgb(255 255 255 / 26%)"
  },
  appBar: {
    boxShadow: "none",
    height: 60,
    marginBottom: theme.spacing(0),
    backgroundColor: "rgb(0,0,0, 0.8)"
    // background:"transparent"// "linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%)",

  },
  navIcon: {
    paddingRight: "20"
  },
  break: {
    alignItems: "center",
    textAlign: "center",
    fontWeight: 700,
    padding: 20,
    backgroundColor: "rgba(100,181,246,0.61)"
  }
}))

const CustomTab = withStyles((_: Theme) => ({
  selected: {
    // backgroundColor: "rgba(0,0,0,0.1)",
    fontWeight: 600,
    boxSizing: "unset",
    color: "#ffffff"
  },
  root: {
    minWidth: 60,
    marginLeft: 40,
    minHeight: 45
  },
  wrapper: {
    flexDirection: "row",
    color: "rgb(255,255,255)",
    textTransform: "initial",
    fontSize: 17,
    "&:hover": {
      fontWeight: 600,
      // backgroundColor: "rgba(0,0,0,0.6)",
      color: "rgb(221,245,241)"
    }
  }
}))(Tab)

// @ts-ignore
function ScrollableTabsButtonAuto({ width }) {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }
  if (isWidthUp("lg", width)) {
    return (
      <div className={classes.root}>
        <AppBar position="sticky" className={classes.appBar}>
          <Toolbar>
            <Container maxWidth="lg">
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item xs={3} container direction={"row"}>
                  <img src={Logo} className={classes.logo} alt="logo"/>
                <Typography classes={{ root: classes.brand }}>Johari</Typography>
                </Grid>
                {/*<CustomTab*/}
                {/*  value={0}*/}
                {/*  onChange={handleChange}*/}
                {/*  style={{ margin: 0 }} label={<span className={classes.brand}>Johari</span>}*/}
                {/*  // icon={<img src={Logo} className={classes.logo} alt="logo"/>}*/}
                {/*  {...a11yProps(0)} />*/}
                <Tabs
                  value={value}
                  onChange={handleChange}
                  centered
                  aria-label="Navbar"
                  TabIndicatorProps={{
                    style: {
                      height: "3px",
                      backgroundColor: "rgb(221,245,241)",
                      borderRadius: 10
                    }
                  }}
                >
                  <CustomTab disableRipple={true} label="Feedback "
                             icon={<QuestionAnswerIcon style={{ marginRight: 10 }}/>}  {...a11yProps(0)} />
                  <CustomTab disableRipple={true} label="Skills"
                             icon={<Poll style={{ marginRight: 10 }}/>}  {...a11yProps(1)} />
                </Tabs>
                <CustomTab
                  value={2}
                  onChange={handleChange}
                  style={{ margin: 0, alignItems: "flex-end", height: 40, width: 40 }} disableRipple={true}
                  icon={<FingerprintIcon style={{ height: 30, width: 30 }}/>} {...a11yProps(2)} />
              </Grid>
            </Container>
          </Toolbar>
        </AppBar>

        <Toolbar className={classes.customizeToolbar}/>
        <Container maxWidth="lg">
          <TabPanel value={value} index={0}>
            <Feedback/>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Skills/>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Settings/>
          </TabPanel>
        </Container>
      </div>
    )
  } else {
    return (

      <Paper className={classes.break} style={{ width: "100%", height: "100ch" }}>
        <Grid container justify={"center"} alignItems={"center"}>
          <Grid container justify={"center"} alignItems={"center"}>
            <SvgIcon style={{ height: "50%", width: "50%", marginTop: 50 }}>
              <EmailSent/>
            </SvgIcon>
          </Grid>
          <Grid container justify={"center"} alignItems={"center"}>
            <Typography classes={{ root: classes.break }} variant={"h6"}> Please choose a screen wider than
              1279px</Typography>
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

export default withWidth()(ScrollableTabsButtonAuto)