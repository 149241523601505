import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import ToggleButton from "@material-ui/lab/ToggleButton"
import { qualities as q } from "../../../data"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toggle: {
      margin: 10
    },
    dialogTitle: {
      color: "rgb(2,75,66)",
      fontWeight: 600,
      fontSize: 18,
      paddingTop: 0
    },
    selected: {
      fontWeight: 600,
      backgroundColor: "rgb(72,166,151, 0.3) !important",
      color: "rgb(0,74,63) !important"
    },
    skillButton: {
      fontSize: 18,
      textTransform: "initial",
      color: "#000000",
      lineHeight: 1,
      fontWeight: 500,
      borderRadius: 20,
      marginBottom: theme.spacing(3),
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      border: "0",
      "&:hover": {
        backgroundColor: "rgba(72,166,151,0.47)",
        color: "rgb(0,119,105)"
      }
    }
  })
)

interface ReviewFeedbackProps {
  name: string
  qualities: Set<number>
  setQualities: React.Dispatch<React.SetStateAction<Set<number>>>
}

export default function ReviewFeedback(props: ReviewFeedbackProps) {
  const classes = useStyles()

  const toggle = (i: number) => {
    let tmp = new Set(props.qualities)
    if (tmp.delete(i)) {
      props.setQualities(tmp)
    } else if (props.qualities.size < 3) {
      tmp.add(i)
      props.setQualities(tmp)
    }

    console.log("clicked", props.qualities)
  }

  const toggleDisabled = (i: number) => {
    return props.qualities.size > 2 && !props.qualities.has(i)
  }

  return (
    <Grid container alignItems={"center"} justify={"center"} style={{ marginTop: 40 }}>
      <Grid item md={4} container style={{ paddingLeft: 40 }}>
        <Typography align="center" className={classes.dialogTitle}>
          {`Select Top 3 Qualities of ${props.name} `}
        </Typography>
      </Grid>
      <Grid item md={8} container>
        {q.map(quality => (
          <Grid item className={classes.toggle} key={quality.id}>
            <ToggleButton
              selected={props.qualities.has(quality.id)}
              onChange={() => toggle(quality.id)}
              disabled={toggleDisabled(quality.id)}
              classes={{ selected: classes.selected }}
              className={classes.skillButton}
            >
              {quality.name}
            </ToggleButton>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}
