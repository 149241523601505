import React from "react"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import {
  Button,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core"
import SendIcon from "@material-ui/icons/Send"
import DeleteIcon from "@material-ui/icons/Delete"
import Snackbar from "@material-ui/core/Snackbar"
import { Alert } from "@material-ui/lab"
import { postData, selfEmail, testEmail, trimString } from "../../services/auth"
import { useMutation, useQuery, gql } from "@apollo/client"
import { GET_USER, UserData } from "../../services/queries"
import ErrorView from "../error-view"
import { LOGIN_API } from "../../constants"
import { Email, EmailType, NewProfileInput, User } from "../../utils/models"
import Loading from "../loading"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    graph: {
      minHeight: 400,
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      borderRadius: 10
    },
    email: {
      color: "#1a1919"
    },
    type: {
      fontWeight: 600
    },
    secondary: {
      color: "rgb(17,82,147)"
    },
    pending: {
      color: "rgb(255,183,77)"
    },
    blocked: {
      color: "rgb(229,115,115)"
    },
    primary: {
      color: "rgb(76,175,80)"
    },
    delIcon: {
      color: "#f44336"
    },
    sendIcon: {
      color: "#2196f3"
    },
    submit: {
      textTransform: "initial",
      fontSize: 17,
      alignItems: "center",
      justifyItems: "center",
      backgroundColor: "rgb(0,76,64)",
      fontWeight: 600,
      color: "#fff",
      marginBottom: 10,
      borderRadius: 10,
      height: "50px",
      width: "100px",
      "&:disable": {
        backgroundColor: "rgb(72,169,153)",
        color: "#fff"
      },
      "&:hover": {
        fontWeight: 600,
        backgroundColor: "rgb(0,121,107)",
        color: "rgb(255,255,255)"
      }
    }
    // heading: {
    //   color: "#000000",
    //   fontSize: 17,
    //   fontWeight: 600
    // }
  })
)

// export enum EmailType {
//   Primary = "Primary",
//   Secondary = "Secondary",
//   Pending = "Pending",
//   Blocked = "Blocked",
// }

export const UPDATE_EMAIL = gql`
mutation updateName($input: ProfileInput!) {
  updateProfile(input: $input) {
    id
    emails {
      value
      type
    }
  }
}
`


// const tempData = new Map<string, EmailType>()
// tempData.set("gneha21@yahoo.in", EmailType.Primary)
// tempData.set("neha.gupta@cern.ch", EmailType.Blocked)
// tempData.set("yneha.comneha.gupta@getfareye.comneha.gupta@getfareye.com", EmailType.Pending)
// tempData.set("neha.gup@icloud.com", EmailType.Secondary)
// tempData.set("gneha500@gmail.com", EmailType.Secondary)

export default function Account() {
  const classes = useStyles()
  const { loading, error: error1, data } = useQuery<UserData>(GET_USER)

  const [primaryEmail, setPrimaryEmail] = React.useState("")
  const [disablePrimaryEmail, setDisablePrimaryEmail] = React.useState(true)
  const [open, setOpen] = React.useState(false)
  const [openAddEmail, setOpenAddEmail] = React.useState(false)
  const tempDataState = data?.user.emails.reduce((map: Map<string, EmailType>, obj: Email) => {
    map.set(obj.value, obj.type)
    // map[obj.id] = obj
    return map
  }, new Map())!

  const [currentPrimaryEmail] = [...tempDataState.entries()].filter(d => d[1] == EmailType.PRIMARY).map(d => (d[0]))
  const [errorValue, setErrorValue] = React.useState(false)
  const [newEmailValue, setNewEmailValue] = React.useState("")

  const [updateEmail] = useMutation<{ updateTemplates: User },
  { input: NewProfileInput }>(UPDATE_EMAIL)

  const handleNewEmail = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setErrorValue(false)
    setNewEmailValue(event.target.value)
  }

  const handleChangePrimaryEmail = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPrimaryEmail(event.target.value as string)
    setDisablePrimaryEmail(false)
    console.log("on change ", event.target.value)
  }

  const update = (emails: Map<string, EmailType>) => {
    updateEmail({variables: {
      input: {
        emails: [...emails.entries()].map(x => ({
          value: x[0],
          type: x[1]
        }))
      }
    }})
  }

  const addNewEmail = (email: string) => {
    let temp = new Map(tempDataState)
    temp.set(email, EmailType.PENDING)
    update(temp)
  }

  const { error, data: data1 } = useQuery<UserData>(GET_USER)
  if (error) return <ErrorView msg={error.message}/>

  const removeEmail = (email: string) => {
    let temp = new Map(tempDataState)
    temp.delete(email)
    update(temp)
  }

  const addPrimaryEmail = () => {
    setDisablePrimaryEmail(true)
    console.log(primaryEmail)
    let temp = new Map(tempDataState)
    temp.set(currentPrimaryEmail, EmailType.SECONDARY)
    temp.set(primaryEmail, EmailType.PRIMARY)
    update(temp)
    setPrimaryEmail("")
    setOpen(true)
  }

  const handleClose = (_?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return
    }
    setOpen(false)
    setOpenAddEmail(false)
  }

  const getTypeColor = (type: EmailType) => {
    switch (type) {
      case EmailType.SECONDARY: {
        return (`${classes.type} ${classes.secondary}`)
      }
      case EmailType.PENDING: {
        return (`${classes.type} ${classes.pending}`)
      }
      case EmailType.BLOCKED: {
        return (`${classes.type} ${classes.blocked}`)
      }
      case EmailType.PRIMARY: {
        return (`${classes.type} ${classes.primary}`)
      }
    }
  }

  const getTitle = (type: EmailType) => {
    switch (type) {
      case EmailType.SECONDARY: {
        return ("Request from your peers on this email will reach you")
      }
      case EmailType.PENDING: {
        return ("Verification Pending")
      }
      case EmailType.BLOCKED: {
        return ("Undeliverable and unverified email")
      }
      case EmailType.PRIMARY: {
        return ("This email will be used for notifications, Password resets, searching you")
      }
    }
  }

  if (loading) return <Loading/>

  if (error1) return <ErrorView msg={error1.message}/>

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Paper className={classes.graph}>
          <Grid container alignItems={"flex-start"}
                style={{ paddingTop: 20, minWidth: 400, maxWidth: "60vw", paddingLeft: 20 }}>
            {[...tempDataState.entries()].map((data) => {
              return (
                <Grid key={`${data[0]}`} container alignItems={"center"}
                      style={{ textAlign: "left", marginBottom: 20 }}>
                  <Grid item xs={5}>
                    <Typography style={{ wordWrap: "break-word", fontWeight: 600 }} className={classes.email}>
                      {data[0]} </Typography>
                  </Grid>
                  <Grid item xs={4} container justify={"center"}>
                    <Tooltip placement="right-start" title={getTitle(data[1])}>
                      <Typography classes={{ root: getTypeColor(data[1]) }}> {data[1]} </Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={3} style={{ textAlign: "right" }}>
                    {(data[1] == EmailType.PENDING || data[1] == EmailType.BLOCKED) && (
                      <Tooltip title="Resend-Verification Email">
                        <IconButton aria-label="Re-send" onClick={() => {
                          postData(LOGIN_API, data[0])
                          setOpenAddEmail(true)
                        }}>
                          <SendIcon classes={{ root: classes.sendIcon }}/>
                        </IconButton>
                      </Tooltip>
                    )}
                    {(data[1] != EmailType.PRIMARY) && (

                      <Tooltip title="Remove Email">
                        <IconButton aria-label="delete" onClick={() => {
                          removeEmail(data[0])
                        }}>
                          <DeleteIcon classes={{ root: classes.delIcon }}/>
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
          <Grid>
            <Divider variant="middle"/>
            <Grid container style={{ marginTop: 20, marginBottom: 30, marginLeft: 20 }} alignItems={"flex-start"}
                  justify={"flex-start"}>
              <Grid container direction={"row"} style={{ marginTop: 20 }}>
                <TextField
                  style={{ minWidth: `50%` }}
                  error={errorValue}
                  value={newEmailValue}
                  onChange={handleNewEmail}
                  variant={"outlined"}
                  id="outlined-email-add-text"
                  label="Add Another Email"
                />
                <Button onClick={() => {
                  setNewEmailValue(trimString(newEmailValue))
                  if (testEmail(trimString(newEmailValue)) || selfEmail(newEmailValue, data1?.user.emails!)) {
                    console.log(newEmailValue)
                    addNewEmail(newEmailValue)
                    setNewEmailValue("")
                    setOpenAddEmail(true)
                    return
                  } else {
                    setErrorValue(true)
                  }
                }}
                        disabled={newEmailValue.length == 0}
                        className={classes.submit}
                        variant="contained"
                        style={{ marginLeft: 20 }}>Add</Button>
                <Snackbar open={openAddEmail} autoHideDuration={5000} onClose={handleClose}>
                  <Alert onClose={handleClose} severity="success">Verification code sent...</Alert>
                </Snackbar>
              </Grid>
            </Grid>
          </Grid>
          <Grid style={{ marginTop: 20, marginBottom: 60 }}>
            <Divider variant="middle"/>
            <Grid container style={{ marginTop: 20, marginLeft: 20 }} alignItems={"flex-start"} justify={"flex-start"}>
              <Grid container direction={"row"} style={{ marginTop: 20 }}>
                <FormControl variant="outlined" style={{ minWidth: `50%` }}>
                  <InputLabel id="demo-simple-select-outlined-label">Change Primary Email</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={primaryEmail}
                    onChange={handleChangePrimaryEmail}
                    label="Change Primary Email"
                  >
                    {[...tempDataState.entries()].filter(d => d[1] == EmailType.SECONDARY)
                      .map(d => <MenuItem key={`${d[0]}-select`} value={d[0]}> {d[0]} </MenuItem>)}
                  </Select>
                </FormControl>
                <Button disabled={disablePrimaryEmail} onClick={addPrimaryEmail} className={classes.submit}
                        variant="contained" style={{ marginLeft: 20 }}>Change </Button>
              </Grid>
              <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">Primary email changed...</Alert>
              </Snackbar>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}