import React  from "react"
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Avatar from "@material-ui/core/Avatar"
import Typography from "@material-ui/core/Typography"
import { green, red, yellow } from "@material-ui/core/colors"
import Grid from "@material-ui/core/Grid"
import Popover from "@material-ui/core/Popover"
import Button from "@material-ui/core/Button"
import Tooltip from "@material-ui/core/Tooltip"
import ScheduleIcon from "@material-ui/icons/Schedule"
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline"
import { navigate } from "gatsby"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded"
import SendRoundedIcon from "@material-ui/icons/SendRounded"
import { DEFAULT_MESSAGE } from "../../constants"
import { FeedbackData, GET_FEEDBACK, GET_USER, REQUEST_FEEDBACK, UserData } from "../../services/queries"
import { useApolloClient, useMutation, useQuery } from "@apollo/client"
import ErrorView from "../error-view"
import { Feedback, SkillMapping } from "../../utils/models"
import { Alert } from "@material-ui/lab"
import Snackbar from "@material-ui/core/Snackbar"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 300,
      padding: 0,
      marginBottom: 0,
      borderRadius: 15,
      "&:hover": {
        // backgroundColor: "rgba(0,0,0,0.15)",
        boxShadow: "4px 4px 4px 2px rgb(255 255 255 / 26%)"
      }
    },
    content: {
      "&:last-child": {
        paddingBottom: 0
      }
    },
    topBar: {
      marginBottom: theme.spacing(2),
      cursor: 'pointer'
    },
    name: {
      lineHeight: "1rem",
      fontWeight: 600,
      fontSize: 14
    },
    email: {
      lineHeight: "1rem",
    },
    expand: {
      transform: "rotate(0deg)",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: "rotate(180deg)"
    },
    avatar: {
      backgroundColor: green[500],
    },
    score: {
      backgroundColor: yellow[700]
    },
    scoreCard: {
      padding: theme.spacing(1)
    },
    button: {
      width: theme.spacing(15),
      height: theme.spacing(5),
      fontSize: 12,
      lineHeight: 1.25,
      borderRadius: 25,
      textTransform: "initial",
      alignItems: "center",
      // border: "1px solid",
      borderColor: "rgba(0,119,105,0.57)",
      "&:hover": {
        backgroundColor: "rgba(72,166,151,0.47)"
      }
    },
    buttonSingle: {
      width: '100%',
      height: theme.spacing(5),
      fontSize: 12,
      lineHeight: 1.25,
      borderRadius: 10,
      textTransform: "initial",
      alignItems: "center",
      // border: "1px solid",
      borderColor: "rgba(72,166,151,0.47)",
      "&:hover": {
        backgroundColor: "rgba(72,166,151,0.47)"
      }
    },
    button1: {
      textTransform: "initial",
      alignItems: "center",
      justifyContent: "flex-start",
      backgroundColor: "rgb(0,0,0)",
      color: "rgb(255,255,255)",
      "&:hover": {
        backgroundColor: "rgb(0,119,105)",
        color: "rgb(255,255,255)"
      }
    },
    lastRow: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    paper: {
      padding: theme.spacing(1)
    }
  })
)

export interface CardData {
  id: string
  type: CardTypes
  uid: string
  name?: string
  email: string
  img?: string
  buttons: ButtonData[]
}

export enum CardTypes {
  // Used in feedback Request page
  FeedbackRequested = 1,
  FeedbackReceived,
  FeedbackRejected,
  // used in feedback provide page
  FeedbackPending,
  FeedbackSpammed,
  FeedbackShared,
}

interface FedbackCardProps {
  id: string
  type: CardTypes
  uid?: string
  name?: string
  email: string
  img?: string
  buttons: ButtonData[]
}

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
}))(Tooltip)

export interface ButtonData {
  name: string
  icon: React.ReactNode
  fn: () => void
}

// A card to a summary of a feedback
export function FeedbackCard(props: FedbackCardProps) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  let status: React.ReactNode

  switch (props.type) {
    case CardTypes.FeedbackPending: {
      status = <LightTooltip title="Pending Request on you" placement="top-start">
        <ScheduleIcon style={{ color: yellow[700] }}/>
      </LightTooltip>
      break
    }

    case CardTypes.FeedbackRequested: {
      status = <LightTooltip title="Pending Request" placement="top-start">
        <ScheduleIcon style={{ color: yellow[700] }}/>
      </LightTooltip>
      break
    }

    case CardTypes.FeedbackReceived: {
      status = <LightTooltip title="Got feedback" placement="top-start">
        <CheckCircleOutlineIcon style={{ color: green[500] }}/>
      </LightTooltip>
      break
    }

    case CardTypes.FeedbackShared: {
      status = <LightTooltip title="Completed sharing" placement="top-start">
        <CheckCircleOutlineIcon style={{ color: green[500] }}/>
      </LightTooltip>
      break
    }
    case CardTypes.FeedbackSpammed: {
      status = <LightTooltip title="Marked as spam" placement="top-start">
        <ErrorOutlineIcon style={{ color: red[500] }}/>
      </LightTooltip>
      break
    }

    case CardTypes.FeedbackRejected: {
      status = <LightTooltip title="Request rejected" placement="top-start">
        <HighlightOffIcon style={{ color: red[500] }}/>
      </LightTooltip>
      break
    }
    // default: {
    //     status = <ErrorOutlineIcon style={{ color: red[500] }} />
    // }
  }

  const client = useApolloClient()
  const [requestFeedback, { error: MError }] = useMutation<{ requestFeedback: Feedback[] },
    { emails: string[]; msg: string; skills: SkillMapping[] }>(REQUEST_FEEDBACK)
  const { error, data } = useQuery<UserData>(GET_USER)
  if (error) return <ErrorView msg={error.message}/>
  const [reqOpen, setReqOpen] = React.useState(false)

  const request = () => {
    setReqOpen(true)
    requestFeedback({
      variables: {
        emails: [props.email],
        msg: DEFAULT_MESSAGE,
        skills: data?.user.skillTemplate.map(x => ({
          sid: x.sid,
          subSkills: x.subSkills
        }))!
      }
    })
      .then(result => {
        // update local cache
        const cacheData = client.readQuery<FeedbackData>({
          query: GET_FEEDBACK
        })

        client.writeQuery<FeedbackData>({
          query: GET_FEEDBACK,

          data: {
            feedbacks: cacheData?.feedbacks!.concat(
              result.data?.requestFeedback!
            )!
          }
        })
      })
      .catch(reason => {
        console.log("Error executing promise", MError, reason)
      })
  }

  const handleClose = (_?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return
    }
    setReqOpen(false)
  }

  return (
    <Grid style={{ backgroundColor: "#fff", borderRadius: 15}} key={props.id}>
      <Snackbar open={reqOpen} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Feedback Request Sent.
        </Alert>
      </Snackbar>
      <Card classes={{ root: classes.root }} raised>
        <CardContent className={classes.content}>
          <Grid
            container
            direction="row"
            justify="space-between"
            className={classes.topBar}
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onClick={handlePopoverOpen}
          >
            <Grid item xs={2}>
              <Avatar className={classes.avatar} src={props.img}/>
            </Grid>

            <Grid item xs={8} container direction="column" justify="flex-start" alignItems="flex-start">
              <Grid item container style={{overflow: 'auto'}}>
                <Typography classes={{root: classes.name}}>
                  {props.name}
                </Typography>
              </Grid>

              <Grid item container style={{overflow: 'auto'}}>
                <Typography classes={{root: classes.email}} variant="caption">{props.email}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={1}>
              {status}
            </Grid>
          </Grid>

          <Popover
            id="mouse-over-popover"
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Grid onMouseLeave={handlePopoverClose} style={{ backgroundColor: "rgb(0,0,0)" }}>
              <Button
                variant="contained"
                className={classes.button1}
                onClick={()=> {
                  typeof window !== `undefined` && navigate(`/profile/?uid=${props.uid}`)
                }}
                startIcon={<AccountCircleRoundedIcon fontSize="large"/>}
              >
                Profile
              </Button>
              {/*<Button*/}
              {/*  variant="contained"*/}
              {/*  className={classes.button1}*/}
              {/*  startIcon={<ShareRoundedIcon fontSize="large"/>}*/}
              {/*>*/}
              {/*  Share*/}
              {/*</Button>*/}
              <Button
                variant="contained"
                className={classes.button1}
                startIcon={<SendRoundedIcon fontSize="large"/>}
                onClick={request}
              >
                Request
              </Button>
            </Grid>
          </Popover>

          {props.buttons.length > 2 && (
            <Grid container spacing={2} justify="space-around">
              {props.buttons.map((data, index) => {
                if (index < 2) {
                  return (
                    <Grid item>
                      <Button
                        size="small"
                        variant="outlined"
                        className={classes.button}
                        onClick={data.fn}
                        startIcon={ data.icon }
                      >
                        {data.name}
                      </Button>
                    </Grid>
                  )
                }
              })}
            </Grid>
          )}

          <Grid
            container
            spacing={2}
            justify="space-around"
            className={classes.lastRow}
          >
            {props.buttons.map((data, index) => {
              let l = props.buttons.length
              if (l ==1) {
                  return (
                    <Grid item xs={12} key={data.name}>
                      <Button
                        variant="outlined"
                        fullWidth
                        className={classes.buttonSingle}
                        onClick={data.fn}
                        startIcon={ data.icon }
                      >
                        {data.name}
                      </Button>
                    </Grid>
                  )
              } else if (l <= 2) {
                if (index < 2) {
                  return (
                    <Grid item key={data.name}>
                      <Button
                        size="small"
                        variant="outlined"
                        className={classes.button}
                        onClick={data.fn}
                        startIcon={ data.icon }
                      >
                        {data.name}
                      </Button>
                    </Grid>
                  )
                }
              }
            })}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}
