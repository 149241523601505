import React from "react"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles"
import Recent from "./recent"
import { Feedback, FeedbackStatus, SkillMapping, User } from "../../../utils/models"
import { Button, LinearProgress, TextField, Tooltip, Typography } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import Snackbar from "@material-ui/core/Snackbar"
import {selfEmail, testEmail, trimString } from "../../../services/auth"
import { useApolloClient, useMutation, useQuery } from "@apollo/client"
import { FeedbackData, GET_FEEDBACK, GET_USER, REQUEST_FEEDBACK, UserData } from "../../../services/queries"
import ErrorView from "../../error-view"
import { DEFAULT_MESSAGE } from "../../../constants"
import Loading from "../../loading"
import { navigate } from "gatsby"
import Graph from "./graph"
import PublicIcon from '@material-ui/icons/Public'
// @ts-ignore
import WelcomeImage from '../../../../static/welcome-feedback.png'
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    graph: {
      minHeight: 300,
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
      borderRadius: 10
    },
    singUp: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      borderRadius: 10,
      backgroundColor: "rgba(129,199,132,0.47)"
    },
    title: {
      fontWeight: 600,
      fontSize: 20,
      color: "rgb(12,12,12)"
    },
    root: {
      position: "relative"
    },
    email: {
      marginTop: 20,
      marginBottom: 10,
      width: "40%",
      textAlign: "center"
    },
    msg: {
      fontWeight: 400,
      fontSize: 18,
      color:  "rgb(0,76,64)",
      marginBottom: 10
    },
    send: {
      justifyItems: "center",
      backgroundColor: "rgb(0,76,64)",
      fontWeight: 600,
      color: "#fff",
      "&:disable": {
        backgroundColor: "rgb(72,169,153)",
        color: "#fff"
      },
      "&:hover": {
        fontWeight: 600,
        backgroundColor: "rgb(0,121,107)",
        color: "rgb(255,255,255)"
      }
    },
    text: {
      // backgroundColor: "rgba(129,199,132,0.53)",
      color: "rgb(255,255,255)",
      fontWeight: 600
      // "&:hover": {
      //   color: "rgba(255,255,255,0.53)",
      //   fontWeight: 600,
      //   backgroundColor:"rgba(129,199,132,0.53)"
      // }
    },
    focused: {
      color: "rgba(255,255,255,0.53)",
      backgroundColor: "rgba(129,199,132,0.53) !important"
    }
  })
)

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 20,
    borderRadius: 15
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === "light" ? 200 : 700]
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "rgba(255,217,26,0.77)"
  }
}))(LinearProgress)

interface ReceivedProps {
  user: User
}

export default function Received(props: ReceivedProps) {
  const classes = useStyles()
  const [errorValue, setErrorValue] = React.useState(false)
  const [newEmailValue, setNewEmailValue] = React.useState("")
  const [errorOpen, setErrorOpen] = React.useState(false)
  const client = useApolloClient()
  const { data: data2, loading: loading2, error: error2 } = useQuery<FeedbackData>(GET_FEEDBACK)

  const [requestFeedback, { error: MError }] = useMutation<{ requestFeedback: Feedback[] },
    { emails: string[]; msg: string; skills: SkillMapping[] }>(REQUEST_FEEDBACK)

  const { loading: loading1, error, data } = useQuery<UserData>(GET_USER)


  if (loading1 || loading2) return <Loading/>

  if (error) return <ErrorView msg={error.message}/>
  if (error2) return <ErrorView msg={error2.message}/>

  const requestedFeedbacks = data2?.feedbacks.filter(f => f.from.id == props.user.id)
  const recieved = requestedFeedbacks?.filter(f => f.status == FeedbackStatus.GIVEN)
  console.log("feedbacks in recieved component", recieved)
  const handleNewEmail = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setErrorValue(false)
    setNewEmailValue(event.target.value)
  }

  const handleClose = (_?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return
    }
    setErrorOpen(false)
  }

  const request = (email: string) => {
    requestFeedback({
      variables: {
        emails: [email],
        msg: DEFAULT_MESSAGE,
        skills: data?.user.skillTemplate.map(x => ({
          sid: x.sid,
          subSkills: x.subSkills
        }))!
      }
    })
      .then(result => {
        // update local cache
        const cacheData = client.readQuery<FeedbackData>({
          query: GET_FEEDBACK
        })

        client.writeQuery<FeedbackData>({
          query: GET_FEEDBACK,

          data: {
            feedbacks: cacheData?.feedbacks!.concat(
              result.data?.requestFeedback!
            )!
          }
        })
      })
      .catch(reason => {
        console.log("Error executing promise", MError, reason)
      })
  }

  return (
    <Grid container direction="column" spacing={1}>
      <Snackbar open={errorOpen} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Please enter business(official) email only. This is to ensure quality feedback and reduce spams.
        </Alert>
      </Snackbar>
      {requestedFeedbacks?.length! < 3 && (
        <Grid item>
          <Paper className={classes.singUp}>
            <Typography classes={{ root: classes.title }}> Hello! </Typography>
            <Typography classes={{ root: classes.msg }}> Let's finish setting up Johari. Ask feedback from 3 or more
              people. </Typography>
            <Grid item>
              <div className={classes.root}>
                <BorderLinearProgress variant="determinate" value={(requestedFeedbacks?.length! * 25)}/>
              </div>
            </Grid>
            <Grid item container direction={"row"} justify={"flex-start"} alignItems={"center"}>
              <Grid item classes={{ root: classes.email }}>
                <TextField
                  fullWidth
                  error={errorValue}
                  value={newEmailValue}
                  onChange={handleNewEmail}
                  id="outlined-email-add-text"
                  label="Email of a collegue..."
                  inputMode={"email"}
                  InputLabelProps={{
                    style: { color: "rgba(255,255,255,0.53)", fontWeight: 600 }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.text
                    }
                  }}
                />
              </Grid>
              <Grid item style={{ padding: 10, marginTop: 10 }}>
                <Button
                  className={classes.send}
                  // disabled={newEmailValue.length < 1}
                  onClick={() => {
                    setNewEmailValue(trimString(newEmailValue))
                    if (!testEmail(newEmailValue) || selfEmail(newEmailValue, data?.user.emails!)) {
                      setErrorValue(true)
                      return
                    }
                    // if (generalEmail(newEmailValue)) {
                    //   setErrorOpen(true)
                    //   setErrorValue(true)
                    //   return
                    // }
                    request(newEmailValue)
                    trackCustomEvent({
                      // string - required - The object that was interacted with (e.g.video)
                      category: "Asked Feedback",
                      // string - required - Type of interaction (e.g. 'play')
                      action: "Click",
                      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                      // number - optional - Numeric value associated with the event. (e.g. A product ID)
                    })
                    setNewEmailValue("")
                    setErrorOpen(false)
                    setErrorValue(false)
                  }}
                  variant="contained"
                > Send </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}
      { recieved?.length! < 1 && (
          <Grid item container style={{marginBottom: 40}}>
            <Grid item><img alt="graph analysis overview" style={{width: '100%', borderRadius: 10}} src={WelcomeImage} />
            </Grid>
           </Grid>
        )}
      { recieved?.length! > 0 && (
      <Grid item>
        <Paper className={classes.graph}>
         <Grid item container justify={"flex-end"}>
           <Tooltip title="Public Profile">
           <PublicIcon onClick={()=>{
               typeof window !== `undefined` && navigate(`/profile/?uid=${data?.user.id}`)
            }} />
           </Tooltip>
         </Grid>
          <Graph feedbacks={recieved!} />
        </Paper>
      </Grid>
      )}
      {/*<Grid item>*/}
      {/*  <Changes/>*/}
      {/*</Grid>*/}
      { recieved?.length! > 0 && (
          <Grid item>
            <Recent feedbacks={recieved!}/>
          </Grid>
      )}
    </Grid>
  )
}
