import React from "react"
import Loadable from '@loadable/component'

import {SkillsPageObject } from "../../utils/models"

interface GraphProps {
  skill: SkillsPageObject
}

interface Point {
    x: string
    y: number
}

const Chart = Loadable(() => import('react-apexcharts'), { ssr: false });

export default function Graph(props: GraphProps) {
    let data: Point[] = []

    let tmp = new Map<string, Set<Point>>()
    props.skill.feedbacks.forEach((x) => {
      const d = new Date(x.updated).toDateString() + " GMT"
      const p = {
        y: Math.round((x.skillFeedbacks[0].subSkillRatings.reduce((sum , current) => sum + current.rating, 0)) / (x.skillFeedbacks[0].subSkillRatings.length)*10)/10,
        x: d,
      }
      if (!tmp.has(d)) {
        tmp.set(d, new Set([p]))
        return
      }
      tmp.get(d)?.add(p)
    })

    data = [...tmp.entries()].reduce((d: Point[], x: [string, Set<Point>]) => {
      d.push({
        x: x[0],
        y: Math.round(([...x[1].values()].reduce((a, b) => a + b.y, 0)/x[1].size)*10)/10
      })
      return d
    }, [])

    data.sort((a,b) =>  new Date(b.x).getTime() - new Date(a.x).getTime())

    // data.sort((a, b) => a.x - b.x) 

//   const graphData = props.skill.feedbacks.map(x => ({
//     name: x.from.email,
//     rating: ((x.skillFeedbacks[0].subSkillRatings.reduce((sum , current) => sum + current.rating, 0)) / (x.skillFeedbacks[0].subSkillRatings.length)),
//     time: new Date(x.updated).toString(),
//   }))

  const series = [{
      name: "Avg Rating",
      data: data,
  }]

  const options =  {
    chart: {
      type: 'area',
      height: 350,
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    
    // title: {
    //   text: 'skill history',
    //   align: 'left'
    // },
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      opposite: true,
      min: 0,
      max: 5
    },
    legend: {
      horizontalAlign: 'left'
    }
  }


  return (
    <Chart options={options} series={series} type="area" height={350} />
  )
}
