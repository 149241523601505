import React from "react"
import { Router } from "@reach/router"
import Home from "../components/home"
import { StringParam, useQueryParam } from "use-query-params"
import { ProtectedRoute } from "../components/protected-route"
import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client"
import { createUploadLink } from "apollo-upload-client"
import { ApolloProvider } from "@apollo/react-hooks"
import Signup from "../components/signup"
// import CookieConsent from "react-cookie-consent

export default function App() {
  const [auth, setAuth] = useQueryParam("auth", StringParam)

  if (auth != undefined && localStorage) {
    localStorage.setItem("jwt", auth)
    setAuth(undefined)
  }

  const jwt = typeof window !== 'undefined' && localStorage.getItem("jwt")
  const httpLink = createUploadLink({ uri: `${process.env.GATSBY_API_URL}/query` })

  const authLink = new ApolloLink((operation, forward) => {
    // Use the setContext method to set the HTTP headers.
    operation.setContext({
      headers: {
        authorization: "BEARER " + jwt
      }
    })
    // Call the next link in the middleware chain.
    return forward(operation)
  })

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  })

  return (
    <ApolloProvider client={client}>
      <Router basepath="/user">
        <ProtectedRoute path="/" as={Home} isLoggedIn={!!jwt}/>
        <ProtectedRoute path="/signup" as={Signup} isLoggedIn={!!jwt}/>
      </Router>
    </ApolloProvider>


    // <div>

    //   <Layout/>

    //   {/* <CookieConsent
    // enableDeclineButton
    // onAccept={() => {alert("yay!")}}
    // onDecline={() => {alert("nay!")}}
    // >
    // This website uses cookies to enhance the user experience.
    // </CookieConsent> */}
    // </div>
  )
}
