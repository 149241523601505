import React from "react"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import MuiDialogContent from "@material-ui/core/DialogContent"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import CloseIcon from "@material-ui/icons/Close"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import MuiDialogActions from "@material-ui/core/DialogActions"


import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles"


const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      paddingBottom: 0,
      textAlign: "center"
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    },
    backButton: {
      position: "absolute",
      left: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  })

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
  back?: () => void
  enableBack?: boolean
}

export const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, back, enableBack, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {enableBack && back ? (
        <IconButton
          aria-label="back"
          onClick={back}
          className={classes.backButton}
        >
          <ArrowBackIcon/>
        </IconButton>
      ) : null}
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon/>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

export const DialogContent = withStyles((theme: Theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}))(MuiDialogContent)


export const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions)
