import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles"
import Tab from "@material-ui/core/Tab"
import Grid from "@material-ui/core/Grid"
import Tabs from "@material-ui/core/Tabs"
import React from "react"
import Paper from "@material-ui/core/Paper"
import { logout } from "../../services/auth"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core"

interface OptionProps {
  onChange: (event: React.ChangeEvent<{}>, value: any) => void
  sectionID: number
}

const CustomTab = withStyles((_: Theme) => ({
  selected: {
    backgroundColor: "rgba(72,169,153,0.22)",
    fontWeight: 600,
    color: "#004a3f",
    borderRadius: 3
  },
  root: {
    height: 5,
    marginTop: 0
  },
  wrapper: {
    alignItems: "flex-start",
    color: "#007769",
    textTransform: "initial",
    fontSize: 17,
    margin: "auto",
    fullWidth: "true"
  }
}))(Tab)

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      borderRadius: 20,
      textTransform: "initial",
      fontWeight: 600,
      fontSize: 16
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(2),
      borderRadius: 10,
      minHeight: 220
    },
    tabGrid: {
      alignContent: "center",
      marginTop: 5,
      display: "flex",
      width: "100%",
      flexContainer: {
        display: "flex"
      }
    },
    logout: {
      justifyItems: "center",
      // backgroundColor: "rgba(32,153,23,0.29)",
      fontWeight: 600,
      color: "#fff",
      "&:hover": {
        fontWeight: 600,
        backgroundColor: "rgba(184,82,114,0.55)",
        color: "rgb(255,255,255)"
      }
    }
  })
)

export default function Options(props: OptionProps) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    console.log("CAme here")
  }

  return (
    <Grid>
      <Paper className={classes.paper}>
        <Grid item container direction="row" className={classes.tabGrid}>
          <Tabs
            orientation="vertical"
            value={props.sectionID}
            onChange={props.onChange}
            aria-label="Vertical tabs"
            TabIndicatorProps={{
              style: {
                height: 45,
                width: 1,
                alignItems: "center"
              }
            }}
            className={classes.tabGrid}
          >
            <CustomTab disableRipple={true} label="Account" {...a11yProps(0)} />
            <CustomTab disableRipple={true} label="Notification" {...a11yProps(1)} />
            <CustomTab disableRipple={true} label="Support" {...a11yProps(2)} />
          </Tabs>
        </Grid>

      </Paper>
      <Grid container justify={"center"} style={{ paddingRight: 40, marginTop: 20 }}>
        <Button className={classes.logout} style={{ width: "30%" }} onClick={handleClickOpen}
                variant="outlined">Logout</Button>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Added Primary Email?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Just make sure you have added a <b>Personal Primary email</b> (as a backup in case you lose business email).
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={logout}>
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}