import React from "react"
import CircularProgress from "@material-ui/core/CircularProgress"
import Backdrop from "@material-ui/core/Backdrop"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff"
    }
  })
)

export default function Loading() {
  const classes = useStyles()
  return (
    <Backdrop className={classes.root} open={true}>
      <CircularProgress color="inherit"/>
    </Backdrop>
  )
}