import React from "react"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Avatar from "@material-ui/core/Avatar"
import Typography from "@material-ui/core/Typography"
import { green } from "@material-ui/core/colors"
import Grid from "@material-ui/core/Grid"
import Rating from "@material-ui/lab/Rating"
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord"
import { Colors, Feedback, Skill } from "../../../utils/models"
import DetailFeeback from "../../detail-feedback/detail-feedback"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      cursor: 'pointer',
      width: 280,
      padding: 0,
      margin: 10,
      boxShadow: "1px 1px 2px rgb(145,145,145)",
      '&:hover':{
        boxShadow: "3px 3px 8px rgb(145,145,145)",
      },
    },
    content: {
      "&:last-child": {
        paddingBottom: 0
      }
    },
    topBar: {
      marginBottom: theme.spacing(2)
    },
    name: {
      lineHeight: "1rem",
      fontWeight: 600,
      fontSize: 14
    },
    email: {
      lineHeight: "1rem",
    },
    expand: {
      transform: "rotate(0deg)",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: "rotate(180deg)"
    },
    skill: {
      fontSize: 13,
      textAlign: "left",
      lineHeight: "1rem",
    },
    avatar: {
      backgroundColor: green[500]
    },
    scoreCard: {
      padding: theme.spacing(1)
    },
    ratingRoot: {
      width: 200,
      display: "flex",
      alignItems: "center"
    },
    iconHover1: { color: Colors.hover1 },
    iconHover2: { color: Colors.hover2 },
    iconHover3: { color: Colors.hover3 },
    iconHover4: { color: Colors.hover4 },
    iconHover5: { color: Colors.hover5 },
    score1: { backgroundColor: Colors.hover1, color: "#000000" },
    score2: { backgroundColor: Colors.hover2, color: "#000000" },
    score3: { backgroundColor: Colors.hover3, color: "#000000" },
    score4: { backgroundColor: Colors.hover4, color: "#000000" },
    score5: { backgroundColor: Colors.hover5, color: "#000000" }
  })
)

interface FeedbackCardProps {
  feedback: Feedback
  skillsMap: Record<number, Skill>
}

// A card to a summary of a feedback
export default function FeedbackCard(props: FeedbackCardProps) {
  const classes = useStyles()
  const [openRequestFeedback, setOpenRequestFeedback] = React.useState(false)
  const handleDetail = () => {
    setOpenRequestFeedback(!openRequestFeedback)
  }

  // const [expanded, setExpanded] = React.useState(false)

  // const handleExpandClick = () => {
  //   setExpanded(!expanded)
  // }

  const ratings = props.feedback.skillFeedbacks.map(f => {
    return (
      {
        key: props.skillsMap[f.sid].name,
        value: f.subSkillRatings.reduce((sum, current) => current.rating + sum, 0) / Math.max(f.subSkillRatings.length, 1)
      }
    )
  })

  const score = ratings.reduce((sum, current) => current.value + sum, 0) / Math.max(ratings.length, 1)
  const getIconFilledVar = (value: number, type: string) => {
    switch (true) {
      case value <= 1: {
        if (type == "fillBgColor") {
          return (classes.score1)
        }
        return (classes.iconHover1)
      }
      case value <= 2 && value > 1: {
        if (type == "fillBgColor") {
          return (classes.score2)
        }
        return (classes.iconHover2)
      }
      case value <= 3 && value > 2: {
        if (type == "fillBgColor") {
          return (classes.score3)
        }
        return (classes.iconHover3)
      }
      case value <= 4 && value > 3: {
        if (type == "fillBgColor") {
          return (classes.score4)
        }
        return (classes.iconHover4)
      }
      case value > 4: {
        if (type == "fillBgColor") {
          return (classes.score5)
        }
        return (classes.iconHover5)
      }
    }
  }

  return (
    <Grid>
    <Card className={classes.root} id={props.feedback.id} onClick={handleDetail}>
      <CardContent className={classes.content}>
        <Grid
          container
          direction="row"
          className={classes.topBar}
        >
          <Grid item xs={2}>
            <Avatar className={classes.avatar} src={props.feedback.to.image}/>
          </Grid>

          <Grid item xs={8} style={{paddingLeft: 6, overflow: 'auto'}} container direction="column" justify="flex-start" alignItems="flex-start">
            <Grid item>
              <Typography classes={{root: classes.name}}>
                {props.feedback.to.name}
              </Typography>
            </Grid>
            <Grid item >
              <Typography classes={{root: classes.email}} variant="caption"> {props.feedback.to.email}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Avatar className={getIconFilledVar(score, "fillBgColor")}>{score.toFixed(1)}</Avatar>
          </Grid>
        </Grid>

        <Grid container className={classes.scoreCard} spacing={1}>
          {/* display first 5 ratings */}
          {ratings.map((data, index) => {
            if (index < 5) {
              return (
                <Grid item container justify="space-between" key={data.key}>
                  <Grid item xs={6}> <Typography classes={{root: classes.skill}}>{data.key}</Typography></Grid>

                  <Grid item xs={6} container alignItems={"center"} justify={"flex-end"}>
                  <Rating
                    readOnly
                    name="read-only"
                    value={data.value}
                    precision={0.5}
                    classes={{
                      iconFilled: getIconFilledVar(data.value, "fillColor")
                    }}
                    icon={<FiberManualRecordIcon style={{height:15, width: 15}} fontSize="inherit"/>}
                  />
                  </Grid>
                </Grid>
              )
            }
          })}
          {/*{*/}
          {/*  expanded && */}
          {/*    ratings.map((data, index) => {*/}
          {/*      */}
          {/*      if (index > 4) {*/}
          {/*        console.log(data)*/}
          {/*        return (*/}
          {/*          <Grid item container justify="space-between" key={data.key}>*/}
          {/*            <Typography variant="body1">{data.key}</Typography>*/}
          {/*            <Rating name="read-only" value={data.value} readOnly />*/}
          {/*          </Grid>*/}
          {/*        )*/}
          {/*      }*/}
          {/*    })*/}
          {/*}*/}

          {/*{ratings.length > 5 && (*/}
          {/*  <Grid item>*/}
          {/*    <IconButton*/}
          {/*      className={clsx(classes.expand, {*/}
          {/*        [classes.expandOpen]: expanded,*/}
          {/*      })}*/}
          {/*      onClick={handleExpandClick}*/}
          {/*      aria-expanded={expanded}*/}
          {/*      aria-label="show more"*/}
          {/*    >*/}
          {/*      <ExpandMoreIcon />*/}
          {/*    </IconButton>*/}
          {/*  </Grid>*/}
          {/*)}*/}
        </Grid>
      </CardContent>
    </Card>
      {
        // important condition to properly unmount the Component
        openRequestFeedback && (
          <DetailFeeback
            open={openRequestFeedback}
            handleclose={handleDetail}
            f={props.feedback}
          />
        )}
    </Grid>
  )
}
