import React from "react"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import FeedbackCard from "./feedback-card"
import { data as SkillsData } from "../../../data"
import { Feedback, Skill } from "../../../utils/models"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root1: {
      color: theme.palette.text.secondary,
      borderRadius: 10,
      marginBottom: 50,
      paddingBottom: 40,
    }
  })
)

// Summary of recent changes to skills
interface RecentProps {
  feedbacks: Feedback[]
}

export default function Recent(props: RecentProps) {
  const classes = useStyles()

  // skills is used to fetch subskills of a skill without iterating through the array
  const skillsMap = SkillsData.reduce((map: Record<number, Skill>, obj: Skill) => {
    map[obj.id] = obj
    return map
  }, {})

  console.log("feedbacks in recent compoenent", props.feedbacks)
  return (
    // <Paper className={classes.root1}>
      <Grid container direction="row"  alignItems="center" justify={"center"}>
        {props.feedbacks.map(f => (
            <Grid item key={f.id}>
              <FeedbackCard feedback={f} skillsMap={skillsMap}/>
            </Grid>
          ))}
      </Grid>
    // </Paper>
  )
}
