import React from "react"
import Grid from "@material-ui/core/Grid"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import FeedbackStepper from "./feedback-stepper"
import { DialogActions, DialogContent, DialogTitle } from "../dialog"
import { Feedback, FeedbackInput, Skill, SkillFeedback } from "../../../utils/models"
import ShareContent from "./share-content"
import ReviewFeedback from "./review-feedback"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert"
import { gql, useMutation } from "@apollo/client"
import ErrorView from "../../error-view"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    requestDialog: {},
    dialogPaper: {
      borderRadius: 15
    },
    dialogTitle: {
      color: "rgb(72,169,153)",
      fontWeight: 600,
      fontSize: 16,
      paddingTop: 0
    },
    sendContainer: {
      paddingLeft: "1vw",
      alignItems: "center",
      textTransform: "initial",
    },
    send: {
      justifyItems: "center",
      backgroundColor: "rgb(0,76,64)",
      fontWeight: 600,
      color: "#fff",
      borderRadius: 10,
      height: "45px",
      width: "50px",
      "&:disable": {
        backgroundColor: "rgb(72,169,153)",
        color: "#fff"
      },
      "&:hover": {
        fontWeight: 600,
        backgroundColor: "rgb(0,121,107)",
        color: "rgb(255,255,255)"
      }
    },
    submit: {
      textTransform: "initial",
      fontSize: 17,
      justifyItems: "center",
      backgroundColor: "rgb(0,76,64)",
      fontWeight: 600,
      color: "#fff",
      marginBottom: 10,
      borderRadius: 10,
      height: "50px",
      width: "90px",
      "&:disable": {
        backgroundColor: "rgb(72,169,153)",
        color: "#fff"
      },
      "&:hover": {
        fontWeight: 600,
        backgroundColor: "rgb(0,121,107)",
        color: "rgb(255,255,255)"
      }
    },
    email: {
      marginBottom: 20,
      paddingLeft: 20
    }
  })
)

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const GIVE_FEEDBACK = gql`
mutation giveFeedback($feedback: FeedbackInput!) {
  giveFeedback(
    feedback: $feedback
  ) {
      id
      status
      from {
        id
        name
        email
        desc
        image
      }
      to {
        id
        name
        email
        desc
        image
      }
      updated
      qualities
      msg
      skillFeedbacks {
        sid 
        skipped
        msg
        improvements
        subSkillRatings{
          sid
          rating
        }
      }
  }
}
`

interface ShareDialogProps {
  fid: string
  open: boolean
  handleClose: () => void
  name: string
  selected: Map<number, Set<number>>
  skillIcons: Record<string, string>
  skillsMap: Record<number, Skill>
}

export default function ShareDialog(props: ShareDialogProps) {
  const classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(0)
  const [msg, setMsg] = React.useState("")

  const steps = () => {
    let skills = selectedArray.map(x => props.skillsMap[x].name)

    // add a review page
    skills.push("Review")
    return skills
  }

  const createRatings = (skillID: number) => [...props.selected.get(skillID)?.values()!]
    .map(x => (
      {
        sid: x,
        rating: 0
      }
    ))

  const createSkill = () => {
    const tmp = new Map(resp)
    const nextSkill = selectedArray[activeStep + 1]

    setMsg("")

    if (nextSkill && !tmp.has(nextSkill)) {
      tmp.set(nextSkill, {
        sid: nextSkill,
        skipped: false,
        msg: "",
        improvements: [],
        subSkillRatings: createRatings(nextSkill)
      })
      setResp(tmp)
    }
  }

  const handleClose = () => {
    props.handleClose()
    // setFinalPage(false)
  }

  let selectedArray = [...props.selected.keys()].map(key => key)

  const [resp, setResp] = React.useState(
    new Map<number, SkillFeedback>().set(selectedArray[0], {
      sid: selectedArray[0],
      skipped: false,
      msg: "",
      improvements: [],
      subSkillRatings: createRatings(selectedArray[0])
    })
  )

  const [qualities, setQualities] = React.useState(new Set<number>())
  const notReviewPage = () => activeStep < selectedArray.length
  const skillID = () => selectedArray[activeStep]
  const currentSkill = () => resp.get(skillID())

  const validate = () => {
    if (currentSkill()?.skipped) {
      return true
    }
    const ratings = [...currentSkill()!.subSkillRatings.values()]
    let ratingsPresent = true
    ratings.forEach(r => {
      console.log("ratings", r, skillID())
      if (r.rating <= 0) {
        console.log(r, "is the rating")
        ratingsPresent = false
      }
    })

    if (!ratingsPresent) {
      setErrorString("Please rate all sub skills!")
      setError(true)
      return false
    }

    if (currentSkill()!.improvements.length < 1) {
      setErrorString("Please select atleast one improvemnt!")
      setError(true)
      return false
    }

    return true
  }

  const next = () => {
    // validation
    if (!validate()) {
      return
    }
    currentSkill()!.msg = msg
    createSkill()
    setActiveStep(activeStep + 1)
  }

  const skip = () => {
    // mark the skill as ptional
    const tmp = new Map(resp)
    tmp.get(skillID())!.skipped = true
    tmp.get(skillID())!.improvements = []
    tmp.get(skillID())!.subSkillRatings = []

    setResp(tmp)
    createSkill()
    setActiveStep(activeStep + 1)
  }

  const [errorString, setErrorString] = React.useState("")
  const [error, setError] = React.useState(false)

  const closeSnackBar = (_?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return
    }
    setError(false)
  }

  const [giveFeedback, { error: gqlError }] = useMutation<{ giveFeedback: Feedback },
    { feedback: FeedbackInput }>(GIVE_FEEDBACK, {
    variables: {
      feedback: {
        id: props.fid,
        qualities: [...qualities.values()],
        msg: msg,
        skillFeedbacks: [...resp.values()].map(v => ({
          sid: v.sid,
          skipped: v.skipped,
          msg: v.msg,
          improvements: v.improvements,
          subSkillRatings: v.subSkillRatings
        }))
      }
    }
  })
  const remoteUpdate = () => {
    const feedbackCurent = {
      id: props.fid,
      qualities: [...qualities.values()],
      msg: msg,
      skillFeedbacks: [...resp.values()].map(v => ({
        sid: v.sid,
        skipped: v.skipped,
        msg: v.msg,
        improvements: [...v.improvements.values()],
        subSkillRating: [...v.subSkillRatings.entries()].map(s => ({
          sid: s[0],
          rating: s[1]
        }))
      }))
    }
    console.log(`current feedback object`)
    console.log(feedbackCurent)
    giveFeedback()
  }

  if (gqlError) return <ErrorView msg={gqlError.message}/>

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      className={classes.requestDialog}
      fullWidth={true}
      maxWidth={"lg"}
      scroll={"paper"}
    >
      <DialogTitle
        classes={{
          root: classes.dialogTitle
        }}
        id="customized-dialog-title"
        onClose={handleClose}
        back={() => {
          setActiveStep(activeStep - 1)
        }}
        enableBack={activeStep > 0}
      >
        <Grid container direction="column">
          {/*<Grid item  className={classes.dialogTitle}>*/}
          {/* Share Feedback for Shubham Jain*/}
          {/*</Grid>*/}
          <Grid item style={{ paddingLeft: 20 }}>
            <FeedbackStepper
              steps={steps()}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ marginTop: 20 }}>
        {(() => {
          if (notReviewPage()) {
            const skillID = selectedArray[activeStep]
            const skill = props.skillsMap[skillID]
            const subSkills = new Set(
              [...skill.subskills].filter(s =>
                props.selected.get(skill.id)?.has(s.id)
              )
            )

            return (
              <ShareContent
                skillID={skillID}
                skillIcon={props.skillIcons[skill.logo]}
                skillName={skill.name}
                description={skill.description}
                subkills={subSkills}
                improvement={skill.improvements}
                resp={resp}
                skillColor={skill.color}
                setResp={setResp}
              />
            )
          }
          if (!notReviewPage()) {
            return (
              <ReviewFeedback
                qualities={qualities}
                setQualities={setQualities}
                name={props.name}
              />
            )
          }
        })()}
      </DialogContent>

      <DialogActions>
        <Grid container direction="row">
          <Grid item md={10} className={classes.email}>
            <TextField
              id="standard-basic"
              label="Have more to say...?"
              multiline={true}
              fullWidth={true}
              value={msg}
              onChange={(event) => {
                setMsg(event.target.value)
              }
              }
            />
          </Grid>
          {notReviewPage() && (
            <Grid item md={2} container direction="row" justify="flex-end" style={{ paddingLeft: 20 }} spacing={2}>
              {
                (!currentSkill()?.skipped) && (
                  <Grid item className={classes.sendContainer}>
                    <Button
                      // className={classes.send}
                      autoFocus
                      onClick={skip}>
                      Skip
                    </Button>
                  </Grid>
                )
              }

              <Grid item className={classes.sendContainer}>
                <Button variant="contained"
                        className={classes.send}
                        autoFocus onClick={next}>
                  Next
                </Button>
              </Grid>

              <Grid item>
                <Snackbar open={error} autoHideDuration={3000} onClose={closeSnackBar}>
                  <Alert onClose={closeSnackBar} severity="error">
                    {errorString}
                  </Alert>
                </Snackbar>
              </Grid>
            </Grid>
          )}

          {!notReviewPage() && (
            <Grid item md={2} container alignItems="center" spacing={5} justify="center"
                  className={classes.sendContainer}>
              <Button
                variant="contained"
                className={classes.submit}
                autoFocus
                disabled={(qualities.size < 1)}
                onClick={() => {
                  console.log(resp)
                  remoteUpdate()
                  trackCustomEvent({
                    // string - required - The object that was interacted with (e.g.video)
                    category: "Shared Feedback",
                    // string - required - Type of interaction (e.g. 'play')
                    action: "Click",
                    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                    // number - optional - Numeric value associated with the event. (e.g. A product ID)
                  })
                  props.handleClose()
                }}>
                Submit
              </Button>
            </Grid>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  )
}