import React from "react"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert"
import { gql, useMutation } from "@apollo/client"


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    graph: {
      minHeight: 400,
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      borderRadius: 10
    },
    support: {
      minWidth: "50vw",
      justifyItems: "flex-start",
      margin: 20
    },
    send: {
      textTransform: "initial",
      fontSize: 16,
      justifyItems: "center",
      backgroundColor: "rgb(0,76,64)",
      fontWeight: 600,
      color: "#fff",
      borderRadius: 10,
      height: "45px",
      width: "80px",
      "&:disable": {
        backgroundColor: "rgb(72,169,153)",
        color: "#fff"
      },
      "&:hover": {
        fontWeight: 600,
        backgroundColor: "rgb(0,121,107)",
        color: "rgb(255,255,255)"
      }
    }
  })
)

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const SUPPORT = gql`
mutation support($msg: String!) {
  support(msg: $msg) {
    id
  }
}`

export default function Support() {
  const classes = useStyles()
  const characterLimit = 280
  const [text, setText] = React.useState("")
  const [disable, setDisable] = React.useState(true)
  const [open, setOpen] = React.useState(false)

  const [support] = useMutation<{ support: string },
  { msg: string }>(SUPPORT)

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value)
    if (event.target.value.length > 10) {
      setDisable(false)
    } else {
      setDisable(true)
    }
  }

  const handleClick = () => {
    if (text.length != 0) {
      support({
        variables: {
          msg: text
        }
      })
      console.log(text)
      setText("")
      setDisable(true)
      setOpen(true)
    }
  }

  const handleClose = (_?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return
    }
    setOpen(false)
  }

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Paper className={classes.graph}>
          <Grid container direction={"column"} justify="flex-start">
            <TextField
              id="outlined-multiline-static"
              label="Feel free to reach out to us with your suggestions or questions..."
              multiline
              rows={10}
              className={classes.support}
              variant="outlined"
              onChange={handleTextChange}
              value={text}
              inputProps={{
                maxLength: characterLimit
              }}
            />
          </Grid>
          <Button disabled={disable} onClick={handleClick} variant="contained" className={classes.send}>
            Submit
          </Button>
          <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">Thanks! We'll soon reach out to you..</Alert>
          </Snackbar>
        </Paper>
      </Grid>
    </Grid>
  )
}