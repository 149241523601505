import React from "react"
import ScrollableTabsButtonAuto from "./scrollable-tabs"
import CssBaseline from "@material-ui/core/CssBaseline"
import { MuiThemeProvider } from "@material-ui/core/styles"
import theme from "../theme"
import { RouteComponentProps } from "@reach/router"

export default function Home(_: RouteComponentProps) {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline/>
      <ScrollableTabsButtonAuto/>
    </MuiThemeProvider>
  )
}