import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import { Divider, Grid, SvgIcon, Typography } from "@material-ui/core"
import React from "react"
import { Colors, Feedback } from "../../utils/models"
import { DialogActions, DialogContent, DialogTitle } from "../feedback/dialog"
import Dialog from "@material-ui/core/Dialog"
import Rating from "@material-ui/lab/Rating"
import { FiberManualRecordRounded } from "@material-ui/icons"
import ToggleButton from "@material-ui/lab/ToggleButton"
import { getdataMap, getQualities } from "../../services/data"
import { graphql, useStaticQuery } from "gatsby"

interface DetailFeedbackProps {
  open: boolean
  handleclose: () => void
  f?: Feedback
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaper: {
      borderRadius: 15
    },
    heading:{
      fontSize: 13,
      fontWeight: 600,
    },
    ssText:{
      fontSize: 14,
      marginBottom: 8,
      fontWeight: 500,
      textAlign: "left",
    },
    skip: {
      color: "#666666",
      fontSize: 14
    },
    date:{
      fontSize: 10,
      marginBottom: 8,
      fontWeight: 500,
      textAlign: "left",
      color: "#a5a3a3"
    },
    iconEmpty1: { color: "rgba(255,171,194,0.3)" },
    iconEmpty2: { color: "rgb(19,204,164,0.3)" },
    iconEmpty3: { color: "rgb(173,175,222,0.3)" },
    iconEmpty4: { color: "rgb(255,67,104,0.3)" },
    iconEmpty5: { color: "rgb(88,155,255,0.3)" },
    iconEmpty6: { color: "rgb(245,133,100,0.3)" },
    iconEmpty7: { color: "rgb(255,207,125,0.3)" },
    toggle1: { backgroundColor: `${Colors.color1} !important`, color: "#000000", },
    toggle2: { backgroundColor: `${Colors.color2} !important`, color: "#000000",},
    toggle3: { backgroundColor: `${Colors.color3} !important`, color: "#000000",},
    toggle4: { backgroundColor: `${Colors.color4} !important`, color: "#ffffff",},
    toggle5: { backgroundColor: `${Colors.color5} !important`, color: "#ffffff",},
    toggle6: { backgroundColor: `${Colors.color6} !important` , color: "#ffffff",},
    toggle7: { backgroundColor: `${Colors.color7} !important`, color: "#000000",},
    backgroundEmpty1: { backgroundColor: "rgba(255,171,194,0.3) !important" },
    backgroundEmpty2: { backgroundColor: "rgb(19,204,164,0.3) !important" },
    backgroundEmpty3: { backgroundColor: "rgb(173,175,222,0.3) !important" },
    backgroundEmpty4: { backgroundColor: "rgb(255,67,104,0.3) !important" },
    backgroundEmpty5: { backgroundColor: "rgb(88,155,255,0.3) !important" },
    backgroundEmpty6: { backgroundColor: "rgb(245,133,100,0.3) !important" },
    backgroundEmpty7: { backgroundColor: "rgb(255,207,125,0.3) !important" },
    iconHover1: { color: Colors.hover1 },
    iconHover2: { color: Colors.hover2 },
    iconHover3: { color: Colors.hover3 },
    iconHover4: { color: Colors.hover4 },
    iconHover5: { color: Colors.hover5 },
    score1: { backgroundColor: Colors.hover1 },
    score2: { backgroundColor: Colors.hover2 },
    score3: { backgroundColor: Colors.hover3 },
    score4: { backgroundColor: Colors.hover4 },
    score5: { backgroundColor: Colors.hover5 },
    from: {
      textAlign: "left",
      fontSize: 17,
      fontWeight: 600,
      color: "#585858"
    },
    selected: {
      height: 30,
      fontSize: 13,
      textTransform: "initial",
      lineHeight: 1,
      fontWeight: 600,
      borderRadius: 10,
      border: "0",
    },
    skillButton: {
      height: 35,
      fontSize: 14,
      textTransform: "initial",
      lineHeight: 1,
      fontWeight: 500,
      borderRadius: 10,
      border: "0",
      color: "#000000"
    },
    exclusive:{
      textAlign: "left",
      fontSize: 13,
      color: "#dddddd"
    },
    msg:{
      textAlign: "left",
      color: "#929292",
      fontSize: 13
    },
    quality:{
      margin: 20,
      fontSize: 13,
      fontWeight: 600,
    },
    qualities:{
        fontWeight: 600,
        fontSize: 16,
        margin: 20
    },
    ssRate:{
      height: 22,
      width: 22,
      marginBottom: 5,
      marginRight: 1,
    },
    optionButton: {
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  })
)

export default function DetailFeeback(props: DetailFeedbackProps) {
  if (!props.f){
    return (
      <p> No feedback given </p>
    )
  }

  const classes = useStyles()
  const handleclose = () => {
    props.handleclose()
  }

  const { allFile } = useStaticQuery(graphql`
  query {
    allFile(
      filter: {
        extension: { eq: "svg" }
        sourceInstanceName: { eq: "skill-icons" }
      }
    ) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
  }
`)
  const iconsMap: Record<string, string> = allFile.edges.reduce(
    (map: any, obj: any) => {
      map[obj.node.name] = obj.node.publicURL
      return map
    },
    {}
  )

  const getIconFilledVar = (value: number, type: string) => {
    switch (true) {
      case value <= 1: {
        if (type == "fillBgColor") {
          return (classes.score1)
        }
        return (classes.iconHover1)
      }
      case value <= 2 && value > 1: {
        if (type == "fillBgColor") {
          return (classes.score2)
        }
        return (classes.iconHover2)
      }
      case value <= 3 && value > 2: {
        if (type == "fillBgColor") {
          return (classes.score3)
        }
        return (classes.iconHover3)
      }
      case value <= 4 && value > 3: {
        if (type == "fillBgColor") {
          return (classes.score4)
        }
        return (classes.iconHover4)
      }
      case value > 4: {
        if (type == "fillBgColor") {
          return (classes.score5)
        }
        return (classes.iconHover5)
      }
    }
  }

  const getEmptyIconColor = (color: Colors) => {
    switch (color) {
      case Colors.color1: {
        return (classes.iconEmpty1)
      }
      case Colors.color2: {
        return (classes.iconEmpty2)
      }
      case Colors.color3: {
        return (classes.iconEmpty3)
      }
      case Colors.color4: {
        return (classes.iconEmpty4)
      }
      case Colors.color5: {
        return (classes.iconEmpty5)
      }
      case Colors.color6: {
        return (classes.iconEmpty6)
      }
      case Colors.color7: {
        return (classes.iconEmpty7)
      }
    }
  }

  const getToggleButtonStyle = (color: Colors, type: string) => {
    switch (color) {
      case Colors.color1: {
        if (type == "selected") {
          return (`${classes.toggle1} ${classes.selected}`)
        }
        return (`${classes.backgroundEmpty1} ${classes.skillButton}`)
      }
      case Colors.color2: {
        if (type == "selected") {
          return (`${classes.toggle2} ${classes.selected}`)
        }
        return (`${classes.backgroundEmpty2} ${classes.skillButton}`)
      }
      case Colors.color3: {
        if (type == "selected") {
          return (`${classes.toggle3} ${classes.selected}`)
        }
        return (`${classes.backgroundEmpty3} ${classes.skillButton}`)
      }
      case Colors.color4: {
        if (type == "selected") {
          return (`${classes.toggle4} ${classes.selected}`)
        }
        return (`${classes.backgroundEmpty4} ${classes.skillButton}`)
      }
      case Colors.color5: {
        if (type == "selected") {
          return (`${classes.toggle5} ${classes.selected}`)
        }
        return (`${classes.backgroundEmpty5} ${classes.skillButton}`)
      }
      case Colors.color6: {
        if (type == "selected") {
          return (`${classes.toggle6} ${classes.selected}`)
        }
        return (`${classes.backgroundEmpty6} ${classes.skillButton}`)
      }
      case Colors.color7: {
        if (type == "selected") {
          return (`${classes.toggle7} ${classes.selected}`)
        }
        return (`${classes.backgroundEmpty7} ${classes.skillButton}`)
      }
    }
  }

  return (
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        onClose={handleclose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        fullWidth={true}
        maxWidth={"lg"}
        scroll={"paper"}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleclose}
        >

        </DialogTitle>
        <DialogContent>
          <Grid container direction={"column"}>
            <Grid item container alignItems={"center"}>
              <Grid item xs={3}></Grid>
              <Grid item xs={4} style={{textAlign: "left"}}>
                <Typography classes={{root: classes.heading}}>360° Feedback</Typography></Grid>
              <Grid item xs={5}  style={{textAlign: "center"}}>
                <Typography classes={{root: classes.heading}}>
                  Things I recommend you to improve</Typography></Grid>
            </Grid>
            {props.f.skillFeedbacks.map((x) => {
              // In case number of days logic for date is needed
              // var t = Math.ceil((Math.abs(new Date().getTime() - new Date(x.updated).getTime())) / (1000 * 3600 * 24))
              return(
                <Grid container key={x.sid} >
                  <Grid item  container alignItems={"center"} justify={"flex-start"} style={{marginTop: 15,}}>
                    <Grid item xs={3} container alignItems={"center"} direction={"column"} style={{alignContent: "flex-start"}}>
                      <Grid item container justify={"center"} alignItems={"center"} style={{alignSelf: "center"}}>
                        <img
                          style={{ height: 50, width: 50}}
                          src={getdataMap(iconsMap)[x.sid].iconURL}
                          alt="logo"
                        />
                      </Grid>
                      <Grid item container justify={"center"} alignItems={"center"}>
                        <Typography classes={{root: classes.from}}>
                          {getdataMap(iconsMap)[x.sid].name}
                        </Typography>
                      </Grid>
                      <Grid item style={{paddingLeft: 20}}>
                        <Rating
                          readOnly
                          name="read-only"
                          value={x.subSkillRatings.reduce((sum, current) => sum + current.rating, 0)/x.subSkillRatings.length}
                          precision={0.5}
                          classes={{
                            iconFilled: getIconFilledVar(x.subSkillRatings.reduce((sum, current) => sum + current.rating, 0)/x.subSkillRatings.length, "fillColor"),
                            iconEmpty: getEmptyIconColor(getdataMap(iconsMap)[x.sid].color),
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography classes={{root: classes.date}}> {new Date(props.f!.updated).toDateString()} </Typography>
                      </Grid>
                    </Grid>
                    { x.skipped && (
                      <Grid> <Typography classes={{root: classes.skip}}>Feedback skipped</Typography> </Grid>
                    )}

                      <Grid item xs={4} container  direction={"column"}>
                        {x.subSkillRatings.map((s)=>{
                          return(
                            <Grid container key={s.sid}>
                              <Grid item xs={6}>
                                <Typography classes={{root: classes.ssText}}> {getdataMap(iconsMap)[x.sid!].subskills[s.sid].name} </Typography>
                              </Grid>
                              <Grid item xs={1}></Grid>
                              <Grid item xs={4} style={{alignSelf: "center"}}>
                                <Rating
                                  readOnly
                                  name="read-only"
                                  value={s.rating}
                                  precision={0.5}
                                  icon={<SvgIcon className={classes.ssRate}>
                                    <FiberManualRecordRounded/>
                                  </SvgIcon>}
                                  classes={{
                                    iconFilled: getIconFilledVar(s.rating, "fillColor"),
                                    iconEmpty: getEmptyIconColor(getdataMap(iconsMap)[x.sid].color),
                                  }}
                                />
                              </Grid>
                            </Grid>
                          )})}
                      </Grid>
                      <Grid item xs={5} container alignItems={"center"} justify={"center"}>
                      {x.improvements.map((s)=>{
                        return(
                          <Grid item key={`${s}-grid`} >
                            <Grid item className={classes.optionButton} key={s}>
                              <ToggleButton key={s} value={false}
                                            className={getToggleButtonStyle(getdataMap(iconsMap)[x.sid].color, "detail")}>
                                {getdataMap(iconsMap)[x.sid].improvements[s]}
                              </ToggleButton>
                            </Grid>
                          </Grid>
                        )})}
                      </Grid>

                    { x.msg && (
                      <Grid item container style={{marginTop: 10}}>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={2} style={{alignSelf: "flex-start"}}>
                          <Typography classes={{root: classes.exclusive}}>Exclusive Feedback</Typography>
                        </Grid>
                        <Grid item xs={7} style={{alignSelf: "flex-start", paddingLeft: 30}}>
                          <Typography classes={{root: classes.msg}}>{x.msg}</Typography>
                        </Grid>
                      </Grid>
                    )}
                      <Grid item xs={12} style={{marginTop: 10}}>
                        <Divider variant="middle" style={{backgroundColor: "rgba(72,169,153,0.42)"}}/>
                      </Grid>
                  </Grid>

                </Grid>

              ) })}
            <Grid item xs={12} container direction={"row"} alignItems={"center"} justify={"center"}>
              <Grid item>
                <Typography classes={{root: classes.quality}}>Your 3 best qualities are : </Typography>
              </Grid>
              {props.f?.qualities.map((q) => {
                return(
                  <Grid item key={q}>
                    <Typography classes={{root: classes.qualities}}>{getQualities()[q]}</Typography>
                  </Grid>
                )})}
              {props.f?.msg && (
                  <Grid item container justify={"center"}>
                    <Typography classes={{root: classes.quality}}>{props.f?.msg}</Typography>
                  </Grid>
                )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
  )
}