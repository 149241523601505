import React from "react"
import { ReactComponent as RatingsIcon } from "../../../../static/ratings.svg"
import InfoIcon from "@material-ui/icons/Info"
import Tooltip from "@material-ui/core/Tooltip"
import Rating from "@material-ui/lab/Rating"
import Avatar from "@material-ui/core/Avatar"
import ToggleButton from "@material-ui/lab/ToggleButton"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import { SvgIcon } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles"
import { Colors, Improvement, SkillFeedback, SubSkill } from "../../../utils/models"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    choseSkillsContent: {
      margin: theme.spacing(2)
    },
    feedbackInfo: {
      marginBottom: 10
    },
    icon: {
      height: "10ch",
      width: "10ch",
      marginRight: 15
    },
    optionButton: {
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    iconFilled1: { color: Colors.color1 },
    iconFilled2: { color: Colors.color2 },
    iconFilled3: { color: Colors.color3 },
    iconFilled4: { color: Colors.color4 },
    iconFilled5: { color: Colors.color5 },
    iconFilled6: { color: Colors.color6 },
    iconFilled7: { color: Colors.color7 },
    toggle1: { backgroundColor: `${Colors.color1} !important` },
    toggle2: { backgroundColor: `${Colors.color2} !important` },
    toggle3: { backgroundColor: `${Colors.color3} !important` },
    toggle4: { backgroundColor: `${Colors.color4} !important` },
    toggle5: { backgroundColor: `${Colors.color5} !important` },
    toggle6: { backgroundColor: `${Colors.color6} !important` },
    toggle7: { backgroundColor: `${Colors.color7} !important` },
    iconEmpty1: { color: "rgba(255,171,194,0.3)" },
    iconEmpty2: { color: "rgb(19,204,164,0.3)" },
    iconEmpty3: { color: "rgb(173,175,222,0.3)" },
    iconEmpty4: { color: "rgb(255,67,104,0.3)" },
    iconEmpty5: { color: "rgb(88,155,255,0.3)" },
    iconEmpty6: { color: "rgb(245,133,100,0.3)" },
    iconEmpty7: { color: "rgb(255,207,125,0.3)" },
    backgroundEmpty1: { backgroundColor: "rgba(255,171,194,0.3)" },
    backgroundEmpty2: { backgroundColor: "rgb(19,204,164,0.3)" },
    backgroundEmpty3: { backgroundColor: "rgb(173,175,222,0.3)" },
    backgroundEmpty4: { backgroundColor: "rgb(255,67,104,0.3)" },
    backgroundEmpty5: { backgroundColor: "rgb(88,155,255,0.3)" },
    backgroundEmpty6: { backgroundColor: "rgb(245,133,100,0.3)" },
    backgroundEmpty7: { backgroundColor: "rgb(255,207,125,0.3)" },
    iconHover1: { color: Colors.hover1 },
    iconHover2: { color: Colors.hover2 },
    iconHover3: { color: Colors.hover3 },
    iconHover4: { color: Colors.hover4 },
    iconHover5: { color: Colors.hover5 },
    score1: { backgroundColor: Colors.hover1 },
    score2: { backgroundColor: Colors.hover2 },
    score3: { backgroundColor: Colors.hover3 },
    score4: { backgroundColor: Colors.hover4 },
    score5: { backgroundColor: Colors.hover5 },
    rating: {
      height: 100,
      width: 100,
      marginRight: 10,
      borderRadius: 20
    },
    iconInfo: {
      width: 20,
      height: 20,
      backgroundColor: "#ffffff",
      color: "rgba(149,144,144,0.36)",
      fontSize: 11
    },
    selected: { color: "#fff !important" },
    skillButton: {
      height: 40,
      fontSize: 15,
      textTransform: "initial",
      color: "#000000",
      lineHeight: 1,
      fontWeight: 500,
      borderRadius: 10,
      border: "0",
      "&:hover": {
        color: "rgb(0,87,77)"
      }
    }
  })
)

interface ShareContentProps {
  skillID: number
  skillName: string
  skillIcon: string
  description: string
  subkills: Set<SubSkill>
  skillColor: Colors
  improvement: Improvement[]
  resp: Map<number, SkillFeedback>
  setResp: React.Dispatch<React.SetStateAction<Map<number, SkillFeedback>>>
}

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: "#fff",
    color: "rgb(0,76,64)",
    boxShadow: theme.shadows[1],
    fontSize: 14,
    margin: 0,
    borderRadius: 10
  },
  arrow: {
    color: "rgba(201,201,201,0.49)"
  }
}))(Tooltip)

export default function ShareContent(props: ShareContentProps) {
  const classes = useStyles()
  const [iconHoverVar, setIconHover] = React.useState(classes.iconHover1)

  const setSubSkillRating = (id: number, newValue: number) => {
    let tmp = new Map(props.resp)
    if (newValue) {
      tmp.get(props.skillID)!.subSkillRatings = tmp.get(props.skillID)!.subSkillRatings.map(r => {
        if (r.sid == id) {
          return {
            sid: id,
            rating: newValue
          }
        }
        return r
      })
      props.setResp(tmp)
    }
  }

  const getSubSkillRating = (id: number) =>
    props.resp.get(props.skillID)!.subSkillRatings.find(r => r.sid == id)

  const calculateScore = () => {
    const ratings = [...props.resp.get(props.skillID)!.subSkillRatings.values()]
    const avg =
      ratings.reduce((sum, current) => current.rating + sum, 0) / Math.max(props.subkills.size, 1)
    return Math.round(avg)
  }

  const toggle = (i: number) => {
    let tmp = new Map(props.resp)
    if (tmp?.get(props.skillID)) {
      let improvements = tmp?.get(props.skillID)!.improvements

      if (tmp?.get(props.skillID)?.improvements.includes(i)) {
        tmp!.get(props.skillID)!.improvements = tmp?.get(props.skillID)!.improvements.filter(x => x != i)
        props.setResp(tmp)
      } else if (improvements.length < 3) {
        improvements.push(i)
        props.setResp(tmp)
      }
    }
  }

  const toggleDisabled = (i: number) => {
    const improvements = props.resp?.get(props.skillID)!.improvements
    return improvements.length > 2 && !improvements.includes(i)
  }

  const getFilledIconColor = (color: Colors) => {
    switch (color) {
      case Colors.color1: {
        return (classes.iconFilled1)
      }
      case Colors.color2: {
        return (classes.iconFilled2)
      }
      case Colors.color3: {
        return (classes.iconFilled3)
      }
      case Colors.color4: {
        return (classes.iconFilled4)
      }
      case Colors.color5: {
        return (classes.iconFilled5)
      }
      case Colors.color6: {
        return (classes.iconFilled6)
      }
      case Colors.color7: {
        return (classes.iconFilled7)
      }
    }
  }

  const getIconFilledVar = (value: number, type: string) => {
    switch (true) {
      case value <= 1: {
        if (type == "fillBgColor") {
          return (classes.score1)
        }
        return (classes.iconHover1)
      }
      case value <= 2 && value > 1: {
        if (type == "fillBgColor") {
          return (classes.score2)
        }
        return (classes.iconHover2)
      }
      case value <= 3 && value > 2: {
        if (type == "fillBgColor") {
          return (classes.score3)
        }
        return (classes.iconHover3)
      }
      case value <= 4 && value > 3: {
        if (type == "fillBgColor") {
          return (classes.score4)
        }
        return (classes.iconHover4)
      }
      case value > 4: {
        if (type == "fillBgColor") {
          return (classes.score5)
        }
        return (classes.iconHover5)
      }
    }
  }

  const getEmptyIconColor = (color: Colors) => {
    switch (color) {
      case Colors.color1: {
        return (classes.iconEmpty1)
      }
      case Colors.color2: {
        return (classes.iconEmpty2)
      }
      case Colors.color3: {
        return (classes.iconEmpty3)
      }
      case Colors.color4: {
        return (classes.iconEmpty4)
      }
      case Colors.color5: {
        return (classes.iconEmpty5)
      }
      case Colors.color6: {
        return (classes.iconEmpty6)
      }
      case Colors.color7: {
        return (classes.iconEmpty7)
      }
    }
  }

  const getToggleButtonStyle = (color: Colors, type: string) => {
    switch (color) {
      case Colors.color1: {
        if (type == "selected") {
          return (`${classes.toggle1} ${classes.selected}`)
        }
        return (`${classes.backgroundEmpty1} ${classes.skillButton}`)
      }
      case Colors.color2: {
        if (type == "selected") {
          return (`${classes.toggle2} ${classes.selected}`)
        }
        return (`${classes.backgroundEmpty2} ${classes.skillButton}`)
      }
      case Colors.color3: {
        if (type == "selected") {
          return (`${classes.toggle3} ${classes.selected}`)
        }
        return (`${classes.backgroundEmpty3} ${classes.skillButton}`)
      }
      case Colors.color4: {
        if (type == "selected") {
          return (`${classes.toggle4} ${classes.selected}`)
        }
        return (`${classes.backgroundEmpty4} ${classes.skillButton}`)
      }
      case Colors.color5: {
        if (type == "selected") {
          return (`${classes.toggle5} ${classes.selected}`)
        }
        return (`${classes.backgroundEmpty5} ${classes.skillButton}`)
      }
      case Colors.color6: {
        if (type == "selected") {
          return (`${classes.toggle6} ${classes.selected}`)
        }
        return (`${classes.backgroundEmpty6} ${classes.skillButton}`)
      }
      case Colors.color7: {
        if (type == "selected") {
          return (`${classes.toggle7} ${classes.selected}`)
        }
        return (`${classes.backgroundEmpty7} ${classes.skillButton}`)
      }
    }
  }

  return (
    <Grid
      container
      flex-wrap="wrap"
      className={classes.choseSkillsContent}
      aria-describedby="scroll-dialog-description">
      <Grid item md={6} alignItems="center">
        {[...props.subkills.values()].map(subskill => {
          return (

            <Grid item container key={subskill.id}>
              <Grid item container alignItems="center" style={{ marginBottom: 7, marginTop: 7 }}>
                <Grid item>
                  <Typography variant="body1"
                    // className={getFilledIconColor(props.skillColor)}
                              style={
                                {
                                  color: "#004c40",
                                  fontSize: 19,
                                  fontWeight: 600
                                }}>
                    {subskill.name}
                  </Typography>
                </Grid>
                <Grid item style={{ marginLeft: 1 }}>
                  <LightTooltip arrow placement="right" title={subskill.description}>
                    <IconButton aria-label="info" style={{ backgroundColor: "#fff", padding: 0, marginLeft: 5 }}>
                      <InfoIcon className={classes.iconInfo}/>
                    </IconButton>
                  </LightTooltip>
                </Grid>
              </Grid>
              <Grid item>
                <Rating
                  key={`${props.skillID}-${subskill.id}`}
                  name={subskill.name}
                  value={getSubSkillRating(subskill.id)?.rating}
                  precision={0.5}
                  onChange={(_, newValue) => {
                    if (newValue) {
                      setSubSkillRating(subskill.id, newValue)
                    }
                  }}
                  onChangeActive={(_, newHover) => {
                    switch (true) {
                      case newHover <= 1: {
                        setIconHover(classes.iconHover1)
                        break
                      }
                      case newHover <= 2 && newHover > 1: {
                        setIconHover(classes.iconHover2)
                        break
                      }
                      case newHover <= 3 && newHover > 2: {
                        setIconHover(classes.iconHover3)
                        break
                      }
                      case newHover <= 4 && newHover > 3: {
                        setIconHover(classes.iconHover4)
                        break
                      }
                      case newHover > 4: {
                        setIconHover(classes.iconHover5)
                        break
                      }
                    }
                  }}
                  icon={<SvgIcon className={classes.rating}>
                    <RatingsIcon/>
                  </SvgIcon>}
                  classes={{
                    iconFilled: getFilledIconColor(props.skillColor),
                    iconEmpty: getEmptyIconColor(props.skillColor),
                    iconHover: iconHoverVar
                  }}
                />
              </Grid>
            </Grid>
          )
        })}
      </Grid>
      <Grid item md={6} container flex-wrap="wrap" direction="column" style={{ paddingTop: 40, paddingLeft: 20 }}
            alignItems="center">
        <Grid item container flex-wrap="wrap" alignItems="center" direction="row" className={classes.feedbackInfo}>
          <Grid item md={2}>
            <img
              className={classes.icon}
              src={props.skillIcon}
              alt={props.skillName}
            />
          </Grid>
          <Grid item md={6}>
            <Grid container direction="row" alignItems="center" flex-wrap="wrap">
              <Grid item>
                <Typography variant="body1" gutterBottom
                            style={
                              {
                                alignItems: "center",
                                color: "#004c40",
                                fontSize: 19,
                                fontWeight: 600
                              }
                            }
                >
                  {props.skillName}
                </Typography>
              </Grid>
              <Grid item>
                <LightTooltip arrow placement="top-start" title={props.description}>
                  <IconButton aria-label="info"
                              style={{ backgroundColor: "#fff", padding: 0, marginBottom: 5, marginLeft: 8 }}>
                    <InfoIcon className={classes.iconInfo}/>
                  </IconButton>
                </LightTooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={4} container justify="center" alignItems="flex-start">
            <Avatar
              style={{
                height: 70,
                width: 70,
                fontWeight: 700,
                fontSize: 40
              }}
              className={getIconFilledVar(calculateScore(), "fillBgColor")}
            >{calculateScore()}</Avatar>
          </Grid>
        </Grid>
        <Grid item container direction="column">
          <Grid item style={{ marginLeft: 12, marginTop: 20 }}>
            <Typography variant="subtitle2" style={{ fontSize: 15, fontWeight: 600, marginBottom: 20 }}>
              3 things I should start doing to improve my {props.skillName}?
            </Typography>
          </Grid>

          <Grid item container style={{ paddingRight: 25 }}>
            {props.improvement.map(imp => (
              <Grid item className={classes.optionButton} key={imp.id}>
                <ToggleButton
                  classes={{ selected: getToggleButtonStyle(props.skillColor, "selected") }}
                  className={getToggleButtonStyle(props.skillColor, "random")}
                  selected={props.resp.get(props.skillID)?.improvements.includes(imp.id)}
                  onChange={() => toggle(imp.id)}
                  disabled={toggleDisabled(imp.id)}
                >
                  {imp.name}
                </ToggleButton>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
