import React, { ChangeEvent } from "react"
import Button from "@material-ui/core/Button"
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Avatar from "@material-ui/core/Avatar"
import Paper from "@material-ui/core/Paper"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import RequestFeedback from "./request-dialog/request-feedback"
import EditableTextField, { EditableTextboxType } from "./editableTextbox"
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle"
import { graphql, useStaticQuery } from "gatsby"
import { data } from "../../data"
// @ts-ignore
import AvatarDefault from "../../../static/avatar.png"
import Tooltip from "@material-ui/core/Tooltip"
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord"
import { Typography } from "@material-ui/core"
import CopyrightIcon from "@material-ui/icons/Copyright"
import { Skill, User } from "../../utils/models"
import { FeedbackData, GET_FEEDBACK } from "../../services/queries"
import { gql, useMutation, useQuery } from "@apollo/client"
import imageCompression from "browser-image-compression"
import { Policy } from "../policy"

interface SummaryProps {
  onChange: (event: React.ChangeEvent<{}>, value: any) => void
  sectionID: number
  user: User
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      borderRadius: 20,
      textTransform: "initial",
      fontWeight: 600,
      fontSize: 17
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    avatar: {
      width: theme.spacing(25),
      height: theme.spacing(25),
      margin: theme.spacing(1),
      marginBottom: theme.spacing(1),
      cursor: `pointer`
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(2),
      borderRadius: 10
    },
    tabGrid: {
      alignContent: "center",
      marginTop: 5,
      display: "flex",
      width: "100%",
      flexContainer: {
        display: "flex"
      }
    },
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    logo: {
      height: 40,
      width: 40,
      marginRight: 4,
      alignItems: "flex-start"
    },
    box: {
      // color:
      right: 50,
      zIndex: 300,
      bottom: 50,
      position: "relative"
    },
    logoText: {
      color: "rgb(9,62,0, 0.5)"
      // alignItems: "flex-start"
    }
  })
)

const CustomTab = withStyles((_: Theme) => ({
  selected: {
    backgroundColor: "rgba(72,169,153,0.22)",
    fontWeight: 600,
    // boxSizing: "unset",
    color: "#004a3f",
    borderRadius: 3
  },
  root: {
    height: 5,
    marginTop: 0
  },
  wrapper: {
    alignItems: "flex-start",
    color: "#007769",
    textTransform: "initial",
    fontSize: 15,
    margin: "auto",
    fullWidth: "true"
  }
}))(Tab)

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`
  }
}

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: "rgba(0,0,0,0.87)",
    color: "rgba(255,255,255,0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
}))(Tooltip)

const UPLOAD_IMAGE = gql`
mutation uploadImage($file: Upload!) {
  uploadImage(file: $file) {
    id
    image
  }
}
`

// Summary of user's profile
export default function Summary(props: SummaryProps) {
  const classes = useStyles()
  const [openRequestFeedback, setOpenRequestFeedback] = React.useState(false)
  const [picture, setPicture] = React.useState<string>(props.user.image == "" ? AvatarDefault : props.user.image)
  const [hoverAvatar, setHoverAvatar] = React.useState(false)
  const handleClickToggleRequestFeedback = () => {
    setOpenRequestFeedback(!openRequestFeedback)
  }

  const handleAvatar = () => {
    if (hoverAvatar) {
      setHoverAvatar(false)
      return
    }
    setHoverAvatar(true)
  }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

  const [uploadImage] = useMutation<{ uploadImage: User },
    { file: File }>(UPLOAD_IMAGE)
  // you should provide one of maxSizeMB, maxWidthOrHeight in the options
  const options = {
    maxSizeMB: 0.3,
    maxWidthOrHeight: 500         // (default: Number.POSITIVE_INFINITY)
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      imageCompression(event.target.files[0], options).then(f => {
        setPicture(URL.createObjectURL(f))
        let file: File
        if (f instanceof File) {
          file = f
        } else {
          file = new File([f], "imgUploadFile", { lastModified: new Date().getTime(), type: f.type })
        }
        uploadImage({
          variables: {
            file: file
          }
        }).catch(reason => {
          console.log("failed to upload file", reason)
        })
      })
    }
  }

  const { allFile } = useStaticQuery(graphql`
  query {
    allFile(
      filter: {
        extension: { eq: "svg" }
        sourceInstanceName: { eq: "skill-icons" }
      }
    ) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
  }
`)
  const skillIcons: Record<string, string> = allFile.edges.reduce(
    (map: any, obj: any) => {
      map[obj.node.name] = obj.node.publicURL
      return map
    },
    {}
  )

  // skills is used to fetch subskills of a skill without iterating through the array
  const skillsMap = data.reduce((map: Record<number, Skill>, obj: Skill) => {
    map[obj.id] = obj
    return map
  }, {})

  const { data: data2 } = useQuery<FeedbackData>(GET_FEEDBACK)

  const sharedFeedbacks = data2?.feedbacks.filter(f => f.to.id == props.user.id)

  const requestedFeedbacks = data2?.feedbacks.filter(f => f.from.id == props.user.id)

  // const receivedFeedback =  requestedFeedbacks?.filter(f => f.status == FeedbackStatus.GIVEN)

  return (
    <Grid container direction={"column"}>
      <Paper className={classes.paper}>
        <Grid container direction="column" alignItems="center">
          <Grid item onMouseEnter={handleAvatar} onMouseLeave={handleAvatar}>
            <input accept="image/*" onChange={handleChange} style={{ display: `none`, position: "absolute" }}
                   id="icon-button-file" type="file"/>
            <label htmlFor="icon-button-file">
              <LightTooltip title={"Change Profile Picture"} placement={"bottom-end"}>
                <Avatar key={picture} className={classes.avatar} src={picture} aria-label="upload picture">Image</Avatar>
              </LightTooltip>
            </label>
          </Grid>
          <Grid container justify="flex-start">
            <Grid item container>
              <EditableTextField type={EditableTextboxType.Name}
                                 text={props.user.name == "" ? "Enter Name" : props.user.name}/>
            </Grid>
            <Grid item container>
              <EditableTextField type={EditableTextboxType.Description}
                                 text={props.user.desc == "" ? "Enter Description" : props.user.desc}/>
            </Grid>
            <Grid item container direction="row" className={classes.tabGrid}>
              <Tabs
                orientation="vertical"
                value={props.sectionID}
                onChange={props.onChange}
                aria-label="Vertical tabs"
                TabIndicatorProps={{
                  style: {
                    height: 45,
                    width: 1,
                    alignItems: "center"
                  }
                }}
                className={classes.tabGrid}
              >
                <CustomTab disableRipple={true} label={`Summary`} {...a11yProps(0)} />
                <CustomTab disableRipple={true}
                           label={`${requestedFeedbacks?.length} Requests Sent`} {...a11yProps(1)} />
                <CustomTab disableRipple={true}
                           label={`${sharedFeedbacks?.length} Requests Received`} {...a11yProps(2)} />
              </Tabs>
            </Grid>
          </Grid>
          <Grid item style={{ marginBottom: 10 }}>
            <Button
              variant="contained"
              fullWidth={true}
              className={classes.button}
              startIcon={<SupervisedUserCircleIcon className={classes.logo}/>}
              style={{
                minHeight: "50px",
                backgroundColor: "rgba(199, 70, 70, 0.3)"
              }}
              onClick={handleClickToggleRequestFeedback}
            >
              Request Feedback
            </Button>

            {
              // important condition to properly unmount the Component
              openRequestFeedback && (
                <RequestFeedback
                  open={openRequestFeedback}
                  handleClose={handleClickToggleRequestFeedback}
                  skillIcons={skillIcons}
                  skillsMap={skillsMap}
                />
              )}
            {/* <Button
            variant="contained"
            size="medium"
            fullWidth={true}
            className={classes.button}
            style={{
              minHeight: "50px",
              backgroundColor: "rgba(71, 70, 199, 0.3)"
            }}
            startIcon={<SendIcon className={classes.logo}/>}
            onClick={handleClickToggleShareFeedback}
          >
            Give Feedback
          </Button>

          {
            // important condition to properly unmount the Component
            openShareFeedback && (
              <ShareDialog
                open={openShareFeedback}
                handleClose={handleClickToggleShareFeedback}
                selected={selected}
                skillIcons={skillIcons}
                skillsMap={skillsMap}
                name={"Shubham Jain"}
              />
            )} */}
          </Grid>
        </Grid>
      </Paper>
      <Grid container justify={"flex-start"} alignItems={"center"} style={{ marginTop: 30 }}>
        {/* yoksel.github.io/url-encoder/*/}
        {/*<Grid item xs={3} style={{ height: 75, paddingLeft: 40 }} container justify={"center"}>*/}
        {/*  <SvgIcon viewBox={"0 0 280 300"} style={{ height: 140, width: 130 }}>*/}
        {/*    <India/>*/}
        {/*  </SvgIcon>*/}
        {/*</Grid>*/}
        <Grid item xs={9} container direction={"row"} alignItems={"center"} justify={"center"}>
          <Grid item xs={1}>
            <CopyrightIcon style={{ alignItems: "flex-end", color: "rgb(9,62,0, 0.5)" }}/>
          </Grid>
          <Grid item xs={11} style={{ paddingLeft: 10 }}>
            <Typography classes={{ root: classes.logoText }}> 2020 Johari</Typography>
          </Grid>
          <Grid item xs={12} className={classes.logoText}>
            <Button onClick={handleClickOpen} className={classes.logoText} startIcon={<FiberManualRecordIcon/>}
                    endIcon={<FiberManualRecordIcon/>}>Privacy</Button>
            <Button onClick={handleClickOpen} className={classes.logoText} endIcon={<FiberManualRecordIcon/>}>Terms</Button>
          </Grid>
          { open && (
              <Policy open={open}
                      handleClose={handleClose}/>
            )}
        </Grid>
      </Grid>
    </Grid>
  )
}
